.searchContainer {
  width: 100%;
  height: 2rem;
  padding: 0 0.5rem;
  grid-column: 1 / -1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--MDDwhite);
  border: 0.05rem solid var(--MDDdefault);
  border-radius: 0.5rem;
  overflow: hidden;
}
.searchContainer.focusedSearch {
  outline: 0.05rem solid var(--MDDprimary);
}
.searchContainer > p {
  width: max-content;
  background-color: transparent;
  color: var(--MDDbasic);
  white-space: nowrap;
  text-overflow: clip;
  overflow-x: visible;
  padding: 0 0.125rem;
}
.searchContainer > p:nth-child(3n) {
  margin-right: 0.25rem;
}
.searchContainer > p > i {
  vertical-align: middle;
  font-size: 0.75rem;
  padding-bottom: 0.15rem;
  transition: color 0.25s;
}
.searchContainer > p > i:hover {
  cursor: pointer;
  color: var(--MDDprimary);
}
#streamSearch {
  width: 100%;
  height: 1.8rem;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: var(--MDDbasic);
  padding: 0 0 0 0.5rem;
  outline: 0.05rem solid transparent;
  transition: outline 0.5s;
  font-size: var(--MDDmediumSize);
  margin-left: 0.25rem;
}

#streamSearch:focus {
  box-shadow: none;
}
