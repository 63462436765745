.metricsContainer {
  position: fixed;
  left: 2rem;
  top: 8.5rem;
  width: 16rem;
  padding: 1rem 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.metricsContainer > div {
  width: 16rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
}
.metricsChart {
  position: relative;
  width: 8rem;
  height: 8rem;
  background-color: var(--MDDdefault);
  border-radius: 4rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.metricsChart.statusOk {
  background-color: var(--MDDstatusOk);
}
.metricsChart.statusWarning {
  background-color: var(--MDDstatusWarning);
}
.metricsChart.statusAlert {
  background-color: var(--MDDstatusAlert);
}
.metricsValue > subscriptionInfo {
  background-color: none;
  font-size: 4rem;
}
.metricsValue {
  width: 6rem;
  height: 6rem;
  background-color: var(--MDDwhite);
  border-radius: 3rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.metricsContainer > div > p,
.metricsStatusContainer > p {
  background-color: transparent;
  width: 10rem;
  text-align: center;
  color: var(--MDDbasic);
}
.metricsValue > p:first-of-type {
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 2rem;
  margin-top: 0.25rem;
  overflow: hidden;
}
.metricsValue > p:nth-of-type(2) {
  font-size: var(--MDDmediumSize);
}
.metricsStatusContainer {
  width: 16rem;
  padding: 1rem 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  cursor: pointer;
}
.metricsStatusContainer > i {
  font-size: 8rem;
}

.subscriptionsMetric p:first-child {
  font-size: 7rem;
  width: 100%;
  text-align: center;
  margin-bottom: -0.25rem;
}

@media only screen and (max-width: 1700px) {
  .metricsContainer {
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    padding: 0.5rem;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  .metricsChart {
    margin-bottom: 0.25rem;
  }
  .metricsStatusContainer {
    width: fit-content;
    padding: 0;
  }
}
