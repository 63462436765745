.tokenFormContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: var(--MDDwhite);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
.tokenFormContainer > label,
.tokenFormContainer > section {
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;
}
.tokenFormContainer > label {
  justify-content: center;
  padding: 0.1rem;
}
.tokenFormContainer > label > input {
  height: 2rem;
  padding: 0 0.75rem;
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
  width: 100%;
  font-size: var(--MDDmediumSize);
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border-radius: 0.25rem;
}
.tokenFormContainer > label > input:focus {
  outline: 0.05rem solid var(--MDDdataviz);
}
.tokenFormContainer > section {
  justify-content: space-between;
}
.tokenFormContainer > section > button:last-of-type {
  background-color: var(--MDDdataviz);
  border: 0.1rem solid var(--MDDdataviz);
}
.tokenFormContainer > section > button:last-of-type:hover {
  background-color: var(--MDDbackground);
  border: 0.1rem solid var(--MDDdataviz);
}
