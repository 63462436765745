.managerCardContainer {
    z-index: 5;
    background-color: transparent;
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;
    height: 100%;
    min-height: 25rem;
    padding: 0.5rem 0;
  }
  .managerCardContainer  > .topDashboardContainer {
    margin-bottom: 2.2rem;
  }
  .managerCardContainer > .form > .row {
    width: 50%;
  }
  
  .managerCardContainer > .form > .row > .col-form-label {
    padding-top: .4rem;
    text-align: right;
  }

  .managerCardContainer > .form > .row > div > input.form-control-plain-text {
    outline: none;
    padding: 0px;
  }