.interfacesContainer {
  position: fixed;
  top: 9rem;
  right: 0;
  height: calc(100vh - 11rem);
  width: fit-content;
  z-index: 7;
  cursor: default;
}
.interfacesContainer > div {
  width: 9rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.singleInterface {
  height: 5rem;
  width: 8rem;
  border-radius: 0.5rem;
  padding: 0.25rem;
  cursor: pointer;
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem;
}
.singleInterface > h5,
.singleInterface > p {
  color: var(--MDDwhite);
  text-align: center;
  width: 100%;
}
.singleInterface > h5 {
  font-size: 1.25rem;
}
.singleInterface > p {
  font-size: var(--MDDmediumSize);
}
.singleInterface > p:last-of-type {
  text-align: left;
}
