.customInputLabel {
  height: 2rem;
  width: 100%;
  background-color: var(--MDDwhite);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.customInputLabel > p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
