.subscribeGlobalContainer {
  height: calc(100vh - 2rem);
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--MDDbasic);
  background-image: url("../../utils/pictures/mddynamics-cover.png");
  background-size: cover;
}
.subscribeGlobalContainer > form,
.subscribeGlobalContainer > article {
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
}
.subscribeGlobalContainer > form > h5,
.subscribeGlobalContainer > article > h5 {
  align-self: flex-start;
  margin-bottom: 1rem;
}
.subscribeGlobalContainer > form > input {
  width: 100%;
  font-size: var(--MDDmediumSize);
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border-radius: 0.25rem;
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
  height: 2rem;
  padding: 0 0.75rem;
}
.subscribeGlobalContainer > form > input:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.subscribeGlobalContainer > form > button,
.subscribeGlobalContainer > article > button {
  align-self: flex-end;
}
.subscribeGlobalContainer > form > div:last-child {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}
