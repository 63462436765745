.endpointsModal {
  position: absolute;
  left: calc(55vw - 4.5rem);
  display: none;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  height: 4.5rem;
  width: 30rem;
  border-radius: 0.5rem;
  background-color: var(--MDDwhite);
  padding: 0.5rem 1rem;
}
.nodesModalBackground {
  position: fixed;
  z-index: 20;
  display: none;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: var(--MDDmodalBackground);
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.nodesModal {
  /* position: absolute;
  z-index: 20; */
  display: none;
  min-height: 30rem;
  width: 100%;
  max-width: 64rem;
  border-radius: 0.5rem;
  background-color: var(--MDDwhite);
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.openedEndpointsModal {
  display: flex;
}
.endpointsModal input {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  height: 1rem;
}
.endpointsModal input::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0.1rem solid var(--MDDprimary);
  height: 1rem;
  width: 1rem;
  border-radius: 0.55rem;
  background-color: var(--MDDprimary);
  cursor: pointer;
  margin-top: -0.45rem;
}
.endpointsModal input::-moz-range-thumb {
  border: 0.1rem solid var(--MDDprimary);
  height: 1rem;
  width: 1rem;
  border-radius: 0.55rem;
  background-color: var(--MDDprimary);
  cursor: pointer;
}
.endpointsModal input::-ms-thumb {
  border: 0.1rem solid var(--MDDprimary);
  height: 1rem;
  width: 1rem;
  border-radius: 0.55rem;
  background-color: var(--MDDprimary);
  cursor: pointer;
}

.endpointsModal input:focus {
  outline: none;
}

.endpointsModal input::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.1rem;
  cursor: pointer;
  background-color: var(--MDDprimary);
  border-radius: 0.1rem;
  border: 0 solid transparent;
}

.endpointsModal input:focus::-webkit-slider-runnable-track {
  background-color: var(--MDDprimary);
}

.endpointsModal input::-moz-range-track {
  width: 100%;
  height: 0.1rem;
  cursor: pointer;
  background-color: var(--MDDprimary);
  border-radius: 0.1rem;
  border: 0 solid transparent;
}

.endpointsModal input::-ms-track {
  width: 100%;
  height: 0.1rem;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
.endpointsModal input::-ms-fill-lower {
  background-color: var(--MDDprimary);
  border: 0 solid transparent;
  border-radius: 2.6px;
}
.endpointsModal input:focus::-ms-fill-lower {
  background-color: var(--MDDprimary);
}
.endpointsModal input::-ms-fill-upper {
  background-color: var(--MDDprimary);
  border: 0 solid transparent;
  border-radius: 2.6px;
}
.endpointsModal input:focus::-ms-fill-upper {
  background-color: var(--MDDprimary);
}
.nodesModal > div:first-of-type {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  padding: 0.5rem;
  box-shadow: var(--MDDdefault) 0.25rem 0.25rem 0.5rem;
}
.nodesModal > div:nth-of-type(2) {
  width: 100%;
  max-width: 100%;
  min-height: 18rem;
  padding: 0.5rem;
  margin: 0;
}
.nodesModal .col {
  border-top: 0.1rem solid var(--MDDdefault);
  border-right: 0.1rem solid var(--MDDdefault);
  padding: 0;
  height: 2rem;
  overflow: hidden;
}
.nodesModal .row:last-of-type > .col {
  border-bottom: 0.1rem solid var(--MDDdefault);
}
.nodesModal .col:first-of-type {
  border-left: 0.1rem solid var(--MDDdefault);
}
.nodesModal .col > button {
  border-radius: 0;
  width: 100%;
}
.nodesModal > div:last-of-type {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
}
.nodesModal > div:last-of-type > p:first-of-type {
  width: 14rem;
}
.nodesModal > div:last-of-type > .nodesModalNav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.nodesModal > div:last-of-type > .nodesModalNav > .nodesModalPlaceholder {
  width: 2rem;
}
.nodesModal > div:last-of-type > div:last-of-type {
  width: 14rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
