.packagesGlobalContainer > *:first-child {
  margin-top: 1rem;
}
article.importStatusContainer:first-child {
  border-top: 0.5rem solid var(--MDDdataviz);
}
.packagesImportContainer {
  width: 100%;
  row-gap: 0.5rem;
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
}
.packagesImportContainer > div {
  height: 100%;
  gap: 1rem;
}
.packagesImportContainer > .inputsContainer {
  max-width: calc(100% - 12rem);
}
.packagesImportContainer > .inputsContainer > p,
.packagesImportContainer > .inputsContainer > div > p,
.packagesImportContainer > .inputsContainer > div > a {
  padding-top: 0.3rem;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.packagesImportContainer > .inputsContainer > div > a:hover {
  color: var(--MDDprimary);
}
.packagesProgress {
  height: 2rem;
  width: 100%;
  background-color: var(--MDDdefault);
}
.packagesProgress > div {
  height: 2rem;
  background-color: var(--MDDbasic);
}
.packagesImportContainer > .inputsContainer > div {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.importStatusContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
  gap: 1rem;
  padding: 0.5rem;
}
.importStatusContainer > .waiting,
.importStatusContainer > .required,
.importStatusContainer > .uploading {
  height: 4rem;
  width: 4rem;
  border-radius: 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.importStatusContainer > .waiting,
.importStatusContainer > .required {
  background-color: var(--MDDprimary);
}
.importStatusContainer > .waiting > button:hover,
.importStatusContainer > .required > button:hover {
  color: var(--MDDwhite);
}
.importStatusContainer > .uploading {
  background-color: var(--MDDdefault);
}
.importStatusContainer > .uploading > p {
  color: var(--MDDwhite);
  position: absolute;
  z-index: 6;
}
.importStatusContainer > .uploading > i {
  color: var(--MDDbasic);
  font-size: 4rem;
  position: absolute;
  z-index: 5;
  animation: uploadingIcon 3s infinite linear;
}
@keyframes uploadingIcon {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.importStatusContainer > .finished > i,
.importStatusContainer > .successful > i {
  color: var(--MDDstatusOk);
  font-size: 4rem;
}
.importStatusContainer > .unfinished > i,
.importStatusContainer > .failed > i {
  color: var(--MDDstatusAlert);
  font-size: 4rem;
}
.importStatusContainer > p {
  max-width: calc(100% - 7rem);
}
.sumedupRessourcesContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 7rem;
  border-radius: 0.5rem;
  gap: 1rem;
  padding: 0.5rem 0;
}
.sumedupRessourcesContainer > p {
  white-space: pre-line;
  text-align: center;
}
.sumedupRessourcesContainer > p:nth-child(3),  /* mask cpu while not retrived from OS */
.sumedupRessourcesContainer > p:nth-child(4) { /* mask disk while not retrived from OS */
  display: none; 
}

.sumedupRessourcesContainer > p > span {
  font-size: 2.5rem;
  font-weight: var(--MDDsemiBold);
}
.packagesTreeContainer {
  width: 100%;
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
  padding: 0.5rem 0;
}
.packagesTreeContainer > p {
  margin: 0 0.5rem;
}
.treeLabelsContainer,
.singleClause {
  width: calc(100% - 1rem);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.treeLabelsContainer {
  margin: 1rem 0.5rem 0.5rem 0.5rem;
  border-top: 0.1rem solid var(--MDDbasic);
  border-bottom: 0.1rem solid var(--MDDbasic);
}
.treeContentContainer {
  width: 100%;
}
.treeLabelsContainer p,
.singleClause p {
  height: 1.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.treeLabelsContainer > p:first-of-type,
.singleClause > div:first-child {
  width: calc(100% - 38.5rem);
}
.singleClause > div:first-child,
.singleClause > div:first-child > div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.singleClause > div:first-child {
  justify-content: space-between;
  margin-left: 0.5rem;
}
.treeLabelsContainer > p:nth-of-type(2) {
  width: 27.5rem;
}
.singleClause > div:nth-of-type(2) {
  width: 30rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.treeLabelsContainer > p:nth-of-type(3) {
  width: 4rem;
}
.treeLabelsContainer > p:last-of-type {
  width: 5rem;
}
/* .singleClause > div:nth-of-type(2) > p {
  cursor: pointer;
} */
.singleClause select {
  display: block;
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  color: var(--MDDbasic);
  padding: 0 0.5rem;
  height: 1.75rem;
  width: 12%;
  min-width: 6.5rem;
  border: 0.05rem solid transparent;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, var(--MDDwhite) 0%, var(--MDDwhite) 100%);
  cursor: pointer;
}
.singleClause:hover select {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(
      to bottom,
      var(--MDDprimaryTransparent) 0%,
      var(--MDDprimaryTransparent) 100%
    );
}
.clauseLabel {
    color: #8e8e8e;
    font-size: 0.9rem;
    display: inline;
    font-weight: 200;
    letter-spacing: -1px;
}
.clauseFlowLabel {
    color: var(--MDDbasic);
    font-size: 1rem;
    display: inline;
}
.treePlaceHolder {
  height: 1.5rem;
  width: 1rem;
}
.treeStatus {
  height: 1rem;
  width: 1rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;
}
.treeStatus.statusAlert {
  background-color: var(--MDDstatusAlert);
}
.treeStatus.statusWarning {
  background-color: var(--MDDstatusWarning);
}
.treeStatus.statusOk {
  background-color: var(--MDDstatusOk);
}
.treeStatus.statusOff {
  background-color: var(--MDDdefault);
}
.treeStatus.statusFatal {
  background-color: var(--MDDstatusFatal);
}
.singleClause {
  background-color: var(--MDDwhite);
  transition: background-color 0.5s;
  margin: 0 0.5rem;
}
.singleClause:hover {
  background-color: var(--MDDprimaryTransparent);
}
.activeClause {
  background-color: var(--MDDprimaryTransparent);
}
.clauseFlow {
  background-color: var(--MDDbackground);
  margin-top: 0.25rem;
  padding-left: 0.5rem;
}
.clauseFlow:hover {
  background-color: var(--MDDbackground);
}
.clauseFlow > p:first-of-type {
  cursor: auto;
}
.singleClause > div:first-child > button {
  width: 4rem;
  height: 1.8rem;
}
.logsModalContainer {
  position: absolute;
  bottom: 14rem;
  left: calc(50vw - 16rem);
  width: fit-content;
  min-width: 11rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border: 0.05rem solid var(--MDDbasic);
  padding: 0.25rem;
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
}
.logsModalContainer > button,
.logsModalContainer > button:hover,
.logsModalContainer > button:focus {
  border-radius: 0;
  width: 100%;
}
.singleClause > button.statusWarning {
  color: var(--MDDstatusWarning);
}
.singleClause > button.statusAlert {
  color: var(--MDDstatusAlert);
}
.singleClause > a > button {
  color: var(--MDDdefault);
  transition: color 0.5s;
}
.singleClause:hover > a > button {
  color: var(--MDDbasic);
}
.packagesGlobalContainer > .linkedStreamsContainer {
  width: 100%;
  padding: 1rem;
  box-shadow: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.linkedStreamsContainer > .activeStreamCard {
  box-shadow: var(--MDDdarkShadow) 0.5rem 0.5rem 0.5rem,
    var(--MDDdarkShadow) -0.5rem -0.5rem 0.5rem,
    var(--MDDdarkShadow) 0.5rem -0.5rem 0.5rem,
    var(--MDDdarkShadow) -0.5rem 0.5rem 0.5rem;
}
.linkedStreamsContainer > .streamCardContainer > div:last-child > p {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}
