.toast-container {
  position: fixed;
  bottom: 2.5rem;
  right: 0;
  z-index: 22;
}
.toast-container > .toast {
  position: relative;
  opacity: 100;
  left: 100rem;
  animation: fadingToast 12s 1 linear;
}
@keyframes fadingToast {
  0% {
    opacity: 100;
    left: 0;
  }
  45% {
    opacity: 100;
    left: 0;
  }
  50% {
    opacity: 0;
    left: 0;
  }
  100% {
    opacity: 0;
    left: 100rem;
  }
}
.toast-header > i {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.successToast {
  color: var(--MDDstatusOk);
}
.errorToast {
  color: var(--MDDstatusAlert);
}
.loadingToast {
  color: var(--MDDprimary);
}
