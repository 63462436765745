.dashboardContainer {
  height: fit-content;
  min-height: 200px;
  padding: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  row-gap: 1rem;
  column-gap: 0.5rem;
  flex-grow: 1;
  z-index: 5;
}
.loginDashboardContainer {
  padding: 0;
}
.dashboardContainer > button {
  position: absolute;
  top: 4.35rem;
  right: 0.75rem;
  z-index: 10;
}
.allCardsContainer {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, max-content));
  row-gap: 1rem;
  column-gap: 0.5rem;
  justify-content: center;
  max-width: 75rem;
  padding-bottom: 1rem;
}
.allCardsContainer > form {
  width: 100%;
  min-width: 20rem;
}
.topDashboardContainer,
.topDashboardContainer > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topDashboardContainer {
  width: 100%;
  grid-column: 1 / -1;
  flex-flow: row wrap;
  overflow-x: visible;
}
.topDashboardContainer > div {
  flex-flow: row nowrap;
  gap: 0.5rem;
  padding: 0 0.5rem;
}
.allCardsContainer > .topDashboardContainer > .fullButton > i {
  padding: 0.1rem;
}
@media only screen and (max-width: 760px) {
  .dashboardContainer {
    padding: 0;
  }
}
