.variablesGlobalContainer,
.streamVariablesContainer {
  justify-content: flex-start;
  align-content: flex-start;
  gap: 0;
}
.streamVariablesContainer > .searchContainer {
  border: 0.05rem solid transparent;
}
.variablesGlobalContainer .row,
.streamVariablesContainer .row {
  width: 100%;
  margin-left: 0;
}
.variablesHeader {
  margin: 0.5rem 0;
}
.variablesHeader p,
.variablesHeader > button {
  width: 100%;
  font-weight: 600;
}
.variablesHeader p {
  background-color: var(--MDDwhite);
  padding-top: 0.25rem;
}
.variablesHeader > button {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 0.6rem;
}
.variablePlaceholder {
  width: 2.2rem;
  height: 2rem;
  margin-left: -0.2rem;
}
.variablesGlobalContainer > section,
.streamVariablesContainer > section {
  width: 100%;
  height: calc(100vh - 13.5rem);
  overflow-y: scroll;
}
.variableContainer {
  width: 100%;
  height: 2rem;
  overflow: hidden;
}
.variableContainer:nth-child(2n) {
  background-color: var(--MDDbackground);
}
.variableContainer > p,
.variableContainer > input {
  background-color: transparent;
  height: 2rem;
  border: 0.1rem solid transparent;
  padding: 0 0.5rem;
  outline: none;
  transition: border 0.25s;
}
.variableContainer > p {
  padding-top: 0.25rem;
}
.variableContainer > input:focus {
  border: 0.1rem solid var(--MDDdefault);
  outline: none;
}
.inactiveVariable > p,
.inactiveVariable > input {
  color: var(--MDDdefault);
}
.variablesGlobalContainer > article:last-of-type {
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.5rem;
}
.streamVariablesContainer {
  width: calc(100% - 1rem);
  margin: 1rem 0.5rem 0 0.5rem;
  padding-top: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
}
