.flightsGlobalContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  row-gap: 1rem;
  column-gap: 0.5rem;
  max-width: 75rem;
  padding: 0 0.5rem 1rem 0.5rem;
}
.flightsGlobalContainer > .topDashboardContainer > div:nth-child(2),
.dataRefreshToggle {
  border-radius: 1rem;
  border: 0.1rem solid var(--MDDbasic);
  gap: 0;
  padding: 0;
  position: relative;
}
.dataRefreshToggle > button,
.dataRefreshToggle > .exportNavBackground {
  width: 4rem;
}
.flightsGlobalContainer > .topDashboardContainer > div > .exportNavBackground {
  width: 13rem;
}
.flightsGlobalContainer .exportNavBackground.estimations ~ button:nth-child(2),
.flightsGlobalContainer .exportNavBackground.previsions ~ button:nth-child(3),
.flightsGlobalContainer .exportNavBackground.off ~ button:nth-child(2),
.flightsGlobalContainer .exportNavBackground.live ~ button:nth-child(3) {
  color: var(--MDDwhite);
}
.dataRefreshToggle > .exportNavBackground.live {
  background-color: var(--MDDprimary);
  transition: left 0.5s, background-color 0.5s;
}
.flightsGlobalContainer > .listHeader > p,
.flightsGlobalContainer > .dependenciesCard > p {
  width: calc(100% / 6);
  min-width: 8rem;
  max-width: 100%;
}
.flightsGlobalContainer > .listHeader > p:first-of-type,
.flightsGlobalContainer > .dependenciesCard > p:first-of-type {
  width: calc(100% / 4);
  min-width: 12rem;
  max-width: 100%;
}
.flightsGlobalContainer > .listHeader > p:last-of-type,
.flightsGlobalContainer > .dependenciesCard > p:last-of-type {
  width: calc(100% / 12);
  min-width: 4rem;
}
.flightsGlobalContainer > .dependenciesCard {
  padding: 0.3rem 0.4rem;
  flex-flow: row wrap;
  transition: height 0.5s, box-shadow 0.5s;
}
.flightsGlobalContainer > .openedFlight {
  height: 5rem;
}
.openedFlight > div:last-child {
  width: 100%;
  height: 2rem;
  background-color: var(--MDDwhite);
  border-radius: 0 0 0.5rem 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5s;
}
.openedFlight > div:last-child > p {
  width: 25%;
}
.flightsSidebar {
  height: 100vh;
  width: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem 0.5rem 2.5rem 0.5rem;
  background-color: var(--MDDwhite);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0.25rem 0 0.5rem var(--MDDdefault);
  z-index: 4;
  transition: width 0.5s;
}
.flightsGlobalContainer .fullSideBarContainer {
  width: 32rem;
  justify-content: flex-start;
}
.flightsSidebar p {
  width: 100%;
  text-align: left;
  height: 2rem;
  padding-top: 0.25rem;
}
.flightsSidebar p:nth-of-type(2) {
  margin-top: 0.5rem;
}
.flightsSidebar pre {
  width: 100%;
  height: calc((100vh - 10rem) / 2);
  background-color: #2d2d2d;
  border-radius: 0.5rem;
  padding: 0.5rem;
  overflow-y: scroll;
}
.flightsSidebar pre > code {
  color: var(--MDDwhite);
  font-size: var(--MDDsmallSize);
}
.flightsShadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--MDDbackgroundTransparent);
}
.flightsGlobalContainer > button {
  margin-top: 10rem;
}
