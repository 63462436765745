.calendarContainer {
  width: 100%;
  max-width: 25rem;
  height: 20rem;
  position: absolute;
  bottom: 14rem;
  z-index: 12;
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
}
.react-calendar {
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: var(--MDDwhite);
  line-height: 1.5rem;
  color: var(--MDDbasic);
  border: 0.05rem solid var(--MDDbasic);
}
.calendarContainer button {
  color: var(--MDDbasic);
  resize: none;
  width: inherit;
  background-color: transparent;
}
.calendarContainer .react-calendar--doubleView {
  width: 700px;
}
.calendarContainer .react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  width: 50%;
  margin: 0.5rem;
}
.calendarContainer .react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.calendarContainer .react-calendar button:enabled:hover {
  cursor: pointer;
}
.calendarContainer .react-calendar__navigation {
  height: 2.2rem;
  margin-bottom: 1rem;
  display: flex;
}
.calendarContainer .react-calendar__navigation button {
  min-width: 2.2rem;
  background-color: transparent;
}
.calendarContainer .react-calendar__navigation button:enabled:hover,
.calendarContainer .react-calendar__navigation button:enabled:focus {
  background-color: var(--MDDprimary);
}
.calendarContainer .react-calendar__navigation button[disabled] {
  background-color: var(--MDDprimary);
}
.calendarContainer .react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: var(--MDDsmallSize);
}
.calendarContainer .react-calendar__month-view__weekdays__weekday {
  padding: 0.5rem;
}
.calendarContainer
  .react-calendar__month-view__weekNumbers
  .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--MDDsmallSize);
  padding: 0.75rem;
}
.calendarContainer .react-calendar__month-view__days__day--neighboringMonth {
  color: var(--MDDdefault);
}
.calendarContainer .react-calendar__year-view .react-calendar__tile,
.calendarContainer .react-calendar__decade-view .react-calendar__tile,
.calendarContainer .react-calendar__century-view .react-calendar__tile {
  padding: 1rem 0.5rem;
}
.calendarContainer .react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75rem 0.5rem;
  background-color: none;
}
.calendarContainer .react-calendar__tile:disabled {
  background-color: var(--MDDbackground);
}
.calendarContainer .react-calendar__tile:enabled:hover,
.calendarContainer .react-calendar__tile:enabled:focus {
  background-color: var(--MDDprimary);
  color: var(--MDDbackground);
}
.calendarContainer .react-calendar__tile--now {
  background-color: var(--MDDstatusAlert);
  color: var(--MDDwhite);
}
.calendarContainer .react-calendar__tile--now:enabled:hover,
.calendarContainer .react-calendar__tile--now:enabled:focus {
  background-color: var(--MDDprimary);
}
.calendarContainer .react-calendar__tile--hasActive {
  background-color: var(--MDDprimary);
  color: var(--MDDbackground);
}
.calendarContainer .react-calendar__tile--hasActive:enabled:hover,
.calendarContainer .react-calendar__tile--hasActive:enabled:focus {
  background-color: var(--MDDprimary);
}
.calendarContainer .react-calendar__tile--active {
  background-color: var(--MDDprimary);
  color: var(--MDDwhite);
}
.calendarContainer .react-calendar__tile--active:enabled:hover,
.calendarContainer .react-calendar__tile--active:enabled:focus {
  background-color: var(--MDDprimary);
}
.calendarContainer .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--MDDbackground);
}
