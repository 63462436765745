.challengeModalContainer {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  /* background-color: #000000ea; */
  background-color: var(--MDDmodalBackground);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.challengeModalContainer > article {
  width: 100%;
  max-width: 37rem;
  height: fit-content;
  max-height: calc(100vh - 2rem);
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
  padding: 1rem;
  position: absolute;
  top: 2.5rem;
}
.challengeModalContainer > article > h5 {
  margin-bottom: 1.5rem;
  color: var(---MDDprimary);
}
.challengeModalContainer > article > p {
  margin-bottom: 1.5rem;
}
.challengeModalContainer > article > form {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.challengeModalContainer > article > form > div {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.1rem;
}
.challengeModalContainer > article > form > div > label {
  width: 100%;
  max-width: 8rem;
  text-align: right;
}
.challengeModalContainer > article > form > div > input {
  width: 100%;
  max-width: 25.8rem;
  font-size: var(--MDDmediumSize);
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border-radius: 0.25rem;
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
  height: 2rem;
  padding: 0 0.75rem;
}
.challengeModalContainer > article > form > div > input:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.challengeModalContainer > article > form > div:last-child {
  margin-top: 2rem;
}
