.codeGlobalContainer {
  position: relative;
}
.codeNameContainer {
  width: 100%;
  row-gap: 0.5rem;
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
  margin-top: 1rem;
}
.inputsContainer > select {
  cursor: pointer;
}
.codeNameContainer > .inputsContainer > div {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.codeEditorContainer,
.logsContainer {
  width: 100%;
  position: relative;
  height: calc(100vh - 22rem);
  border-radius: 0.5rem;  
}
.codeEditorContainer {
  overflow: scroll;
}
.fullScreenCodeEditor {
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  width: calc(100vw - 1rem);
  height: calc(100vh - 3.5rem);
  z-index: 6;
}

.codeGlobalContainer > .codeEditorContainer .codeInput,
.codeGlobalContainer > .codeEditorContainer > .codeOutput {
  min-height: calc(100vh - 22rem);
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  border: none;
  font-family: "Source Code Pro", monospace;
  #font-family: monospace;
  font-weight: 100;
  white-space: pre;
  line-height: 1.5em;
  word-wrap: break-word;
  margin: 0;
  border-radius: 0.5rem;
  outline: none;
  font-size: 1.02rem;
  letter-spacing: -0.07rem;
}

.codeGlobalContainer > .fullScreenCodeEditor .codeInput,
.codeGlobalContainer > .fullScreenCodeEditor > .codeOutput {
  min-height: calc(100vh - 3.5rem);
  height: calc(100vh - 3.5rem);
}

.codeInput {
  padding: 1rem;
  color: transparent;
  background-color: transparent;
  resize: none;
  z-index: 3;
  caret-color: white;
  padding-top: 1rem;
  white-space: pre-wrap;
  tab-size: 4;
}

.codeOutput {
  pointer-events: none;
  overflow-y: auto;
}

.codeOutput > code {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 1rem;
  display: block;
  font-family: "Source Code Pro", monospace;
  white-space: pre-wrap;
  color: #a4cfea;
}
.codeOutput > code > .token.string {
  color: #76c388;
}
.codeOutput > code > .token.function {
  color: #60d2ff;
}
.codeOutput > code > .token.operator {
  color: #2abaff;
}
.codeOutput > code > .token.keyword {
  color: #2a8cff;
}
.codeOutput > code > .token.class-name {
  color: #60d2ff;
}
.codeOutput > code > .token.number {
  color: #dffd73;
}
.codeOutput > code > .token.comment {
  color: #ced3d6;
}
.codeOutput > code > .token.punctuation {
  color: #60d2ff;
}

.logsContainer {
  min-height: 3rem;
  height: fit-content;
  border-radius: 0.5rem;
  background-color: #2d2d2d;
  padding: 10px 15px;
}
.logsContainer > p {
  width: 100%;
  font-size: var(--MDDsmallSize);
  font-family: "Source Code Pro", monospace;
  font-weight: 300;
}
.codeButtonsContainer {
  position: sticky;
  top: 1rem;
  left: calc(100% - 13rem);
  width: 12rem;
  z-index: 10;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}
.streamToggle {
  border-radius: 1rem;
  border: 0.1rem solid var(--MDDprimary);
  gap: 0;
  padding: 0;
  position: relative;
  width: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  margin: 0 0.5rem;
}
.streamToggle > .streamToggleBackground {
  background-color: var(--MDDprimary);
  position: absolute;
  left: 0;
  z-index: -1;
  border-radius: 1rem;
  transition: left 0.5s;
}
.streamToggle > button,
.streamToggle > button:focus,
.streamToggle > .streamToggleBackground {
  width: 3.5rem;
  height: 1.5rem;
  color: var(--MDDwhite);
}
.streamToggle > button {
  line-height: initial;
}
.streamToggle > .streamToggleBackground.shareStream {
  background-color: var(--MDDprimary);
  transition: left 0.5s, background-color 0.5s;
}
/* .streamToggleBackground.sharedVersion ~ button:nth-child(3),
.streamToggleBackground.off ~ button:nth-child(2) {
  color: var(--MDDwhite);
} */
.codeButtonsContainer > button,
.codeButtonsContainer > button:focus {
  /* position: sticky;
  top: 1rem;
  left: calc(100% - 3rem);
  z-index: 10; */
  color: var(--MDDwhite);
}
