.subscriptionDashboardContainer {
  height: fit-content;
  min-height: 200px;
  width: 100%;
  max-width: 80%;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1rem;
  column-gap: 0.5rem;
}
.subscriptionsLabels {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3rem;
  padding: 0.25rem 0.75rem 0 2.55rem;
  border-bottom: 0.05rem solid var(--MDDbasic);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}
.subscriptionsLabels > p {
  color: var(--MDDbasic);
  text-align: left;
  width: 100%;
}
.subscriptionsLabels > p:nth-child(4),
.subscriptionsLabels > p:nth-child(5) {
  max-width: 7rem;
}
.subscriptionDashboardContainer > .noElementDisclaimer > h4 {
  color: var(--MDDsubscriptions);
}
