.datavizGlobalContainer {
  padding: 0;
  gap: 0;
  align-content: flex-start;
  position: relative;
}
.datavizDashboard {
  position: relative;
  top: 2rem;
  width: 100%;
  min-height: calc(100vh - 4rem);
  height: fit-content;
  display: grid;
  grid-auto-columns: 1rem;
  /* grid-template-columns: repeat(50, 1fr); */
  grid-auto-rows: 1rem;
  gap: 1rem;
  /* row-gap: 1rem;
  column-gap: calc(100% / 50); */
  padding: 1rem;
}
.datavizDashboard > .firstWidget {
  width: 20rem;
  height: fit-content;
  position: absolute;
  top: 40%;
  left: calc(50% - 10rem);
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.1rem 0.25rem,
    var(--MDDshadow) -0.1rem 0.2rem 0.25rem;
  background-color: var(--MDDwhite);
  color: var(--MDDdataviz);
}
.datavizDashboard > .widgetPosition {
  width: 18rem;
  height: 12rem;
  background-color: transparent;
  border-radius: 0.5rem;
  border: none;
  color: transparent;
  font-size: 2rem;
  box-shadow: none;
  transition: color 0.5s, background-color 0.5s, box-shadow 0.5s;
}
.widgetPosition:hover,
.widgetPosition.lockedPosition {
  background-color: var(--MDDbackground);
  color: var(--MDDdefault);
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
}
.widgetTypesModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 6;
  top: 0;
  left: 0;
  background-color: var(--MDDmodalBackground);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.widgetTypesModal > article {
  position: relative;
  width: 100%;
  max-width: 60rem;
  height: fit-content;
  padding: 2rem;
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, max-content));
  justify-content: center;
  align-content: flex-start;
  gap: 1rem;
}
.widgetTypesModal > article > button:not(.mddSmallButton) {
  width: 100%;
  max-width: 10rem;
  height: 7rem;
  border: none;
  background-color: var(--MDDbackground);
  color: var(--MDDdataviz);
  font-size: var(--MDDlargeSize);
  border-radius: 0.5rem;
  transition: color 0.5s, background-color 0.5s;
}
.widgetTypesModal > article > button:not(.mddSmallButton):hover {
  background-color: var(--MDDdefault);
  color: var(--MDDwhite);
}
.widgetTypesModal > article > .mddSmallButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
