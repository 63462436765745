.mddButton,
.mddButton:focus,
.mddLongButton,
.mddLongButton:focus,
.mddSmallButton,
.mddSmallButton:focus {
  color: var(--MDDbackground);
  height: 2rem;
  border: 0.1rem solid transparent;
  transition: color 0.5s, background-color 0.5s, border 0.5s;
  background-color: var(--MDDprimary);
  padding: 0;
  border-radius: 0.5rem;
  overflow: hidden;
}
.mddButton {
  width: 7rem;
}
.mddLongButton {
  width: 13rem;
}
.mddSmallButton {
  width: 2rem;
}
.mddButton:hover,
.mddLongButton:hover,
.streamCardContainer:hover .mddButton,
.mddSmallButton:hover {
  background-color: var(--MDDbackground);
  color: var(--MDDprimary);
  border: 0.1rem solid var(--MDDprimary);
}
.mddButton:focus,
.mddLongButton:focus,
.mddSmallButton:focus {
  outline: 0 none;
  box-shadow: none;
}

.basicButtonColor:hover,
.secondaryButton:hover {
  background-color: var(--MDDbackground);
  color: var(--MDDbasic);
  border: 0.1rem solid var(--MDDbasic);
}
.secondaryButton,
.secondaryButton:focus {
  background-color: var(--MDDdefault);
  color: var(--MDDbasic);
}
.altButtonColor,
.altButtonColor:focus,
.activeAltButton,
.activeAltButton:focus,
.inactiveAltButton,
.inactiveAltButton:focus {
  background-color: transparent;
  color: var(--MDDbasic);
  transition: color 0.5s;
  border: 0.1rem solid transparent;
}
.activeAltButton,
.activeAltButton:focus {
  color: var(--MDDprimary);
}
.inactiveAltButton,
.inactiveAltButton:focus {
  color: var(--MDDdefault);
}
.altButtonColor:hover,
.activeAltButton:hover,
.inactiveAltButton:hover {
  color: var(--MDDprimary);
  border: 0.1rem solid transparent;
  background-color: transparent;
}
.basicButtonColor,
.basicButtonColor:focus {
  background-color: var(--MDDbasic);
  border: 0.1rem solid var(--MDDbasic);
}
.alertButtonColor,
.alertButtonColor:focus {
  background-color: var(--MDDstatusAlert);
  border: 0.1rem solid var(--MDDstatusAlert);
}
.offButtonColor,
.offButtonColor:focus {
  background-color: #fff;
  border: none;
  color: #848484;
}
.backgroundButtonColor,
.backgroundButtonColor:focus {
  background-color: var(--MDDbackground);
  color: var(--MDDbasic);
}
.subscriptionCardContainer:hover > div > .backgroundButtonColor,
.openedCard > div > .backgroundButtonColor {
  background-color: var(--MDDbasic);
  color: var(--MDDwhite);
  border: 0.1rem solid transparent;
}
.subscriptionCardContainer > div > .backgroundButtonColor:hover,
.openedCard > div > .backgroundButtonColor:hover {
  color: var(--MDDprimary);
}
.inactiveButton,
.inactiveButton:hover {
  background-color: var(--MDDwhite);
  border: 0.1rem solid var(--MDDdefault);
  color: var(--MDDdefault);
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  font-size: 1rem;
  font-family: var(--MDDfont);
  padding-top: 0.15rem;
  cursor: default;
}
.mddSmallButton.inactiveButton,
.mddSmallButton.inactiveButton:hover {
  background-color: transparent;
  color: var(--MDDdefault);
  border: 0.1rem solid transparent;
}
.fakeButton {
  padding-top: 0.15rem;
}
.fullButton,
.inactiveButton.fullButton {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.mddButton > i {
  font-size: 1.5rem;
}
.mddButton > i.smallIcon {
  font-size: 0.75rem;
}
