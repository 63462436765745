.confirmModalContainer {
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--MDDmodalBackground);
}
.confirmModalContainer > article {
  width: 100%;
  max-width: 500px;
  min-height: 150px;
  background-color: var(--MDDwhite);
  border: 0.1rem solid var(--MDDbasic);
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.confirmModalContainer > article > p:first-child,
.confirmModalContainer > article > p:nth-child(3) {
  font-size: var(--MDDsmallSize);
  width: 100%;
}
.confirmModalContainer > article > p:nth-child(3) {
  margin-bottom: 1rem;
}
