.loginGlobalContainer {
  height: 100vh;
  width: 100vw;
  background-color: var(--MDDbasic);
  background-image: url("../../utils/pictures/mddynamics-cover.png");
  background-size: cover;
}
.loginGlobalContainer > .loginInputContainer {
  position: fixed;
  top: 40vh;
  left: calc(50% - 15rem);
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
  z-index: 5;
  opacity: 1;
  animation: appearingInput 3s 1 linear;
}
@keyframes appearingInput {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.loginGlobalContainer > .loginInputContainer button > i::before {
  vertical-align: -0.05rem;
}
.loginGlobalContainer > .loginInputContainer > .loginPlaceholder {
  height: 1.5rem;
  width: 2rem;
}
.loginGlobalContainer > .loginInputContainer > .loginPlaceholder,
.loginGlobalContainer > .loginInputContainer > button {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0.5rem;
}
.loginGlobalContainer > .loginInputContainer > button:hover {
  background-color: transparent;
  border: 0.1rem solid transparent;
}
.loginGlobalContainer > .loginInputContainer > article {
  width: 100%;
  max-width: 26rem;
  background-color: var(--MDDwhite);
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.loginGlobalContainer > .loginInputContainer > article > input {
  background-color: transparent;
  border: none;
  outline: none;
  width: calc(100% - 2.25rem);
}
.loginGlobalContainer > .loginInputContainer > .incorrectInput > input {
  color: var(--MDDstatusAlert);
}
.loginGlobalContainer > .loginInputContainer > a {
  width: 50%;
  color: var(--MDDwhite);
  font-size: var(--MDDsmallSize);
  padding-top: 0.25rem;
}
.loginGlobalContainer > .loginInputContainer > a:first-of-type {
  text-align: left;
  padding-left: 2.75rem;
}
.loginGlobalContainer > .loginInputContainer > a:last-of-type {
  text-align: right;
  padding-right: 2.75rem;
}
.loginGlobalContainer > .loginInputContainer > a:hover {
  color: var(--MDDprimary);
}
.loginGlobalContainer > .loginTitleContainer {
  position: fixed;
  bottom: 40vh;
  left: calc(50% - 22.5rem);
  width: 100%;
  max-width: 45rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  transition: bottom 1.5s;
  z-index: 6;
}
.activeLogin > .loginTitleContainer {
  bottom: 1rem;
}
.loginGlobalContainer > .loginTitleContainer h1,
.loginGlobalContainer > .loginTitleContainer h5 {
  color: var(--MDDprimary);
}
.loginGlobalContainer > .loginTitleContainer > .loginPageTitle {
  font-size: 2.8rem;
  color: #fff;
}

.loginGlobalContainer > .loginTitleContainer .loginPageSubtitle {
  font-size: 2rem;
}

.loginGlobalContainer > .loginTitleContainer > div {
  #align-self: flex-end;
  display: flex;
  flex-flow: row nowrap;
  #justify-content: flex-end;
  #align-items: flex-start;
  width: 100%;
  #gap: 0.25rem;
  padding-left: 55%;
}
.loginGlobalContainer > .loginTitleContainer span {
  color: var(--MDDwhite);
}
.loginGlobalContainer > .loginTitleContainer img {
  height: 1.5rem;
}
.loginPageTitle {
  #text-align: center;
  padding-top: 1.1rem;
  color: rgb(43, 176, 252);
}