.dataWidget {
  position: relative;
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
  overflow: hidden;
  opacity: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  transition: opacity 0.5s;
}
.fullScreenDataWidget {
  position: fixed;
  top: 2rem;
  left: 0;
  z-index: 2;
}
.dataWidget > div:first-of-type {
  width: 100%;
  height: 2rem;
  position: relative;
  background-color: transparent;
  cursor: grab;
  overflow-y: hidden;
  transition: background-color 0.5s;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.dataWidget > div:first-of-type:hover {
  background-color: var(---MDDbackground);
}
.dataWidget > div:first-of-type > input {
  z-index: 1;
  background-color: transparent;
  border: none;
  font-size: var(--MDDmediumSize);
  color: transparent;
  text-align: center;
  outline: none;
  caret-color: var(--MDDbasic);
}
.dataWidget > div:first-of-type > h6 {
  display: inline-block;
  position: absolute;
}
.dataWidget > div:first-of-type > div {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}
.dataWidget > div:first-of-type > div > .mddSmallButton {
  color: var(--MDDdefault);
}
.dataWidget > div:first-of-type > div > .mddSmallButton:hover {
  color: var(--MDDbasic);
}
.dataWidget > .mddSmallButton,
.dataWidget > .mddSmallButton:focus {
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
  border-radius: 0.75rem;
  transform: rotate(-45deg);
  height: 1.5rem;
  width: 1.5rem;
  cursor: nwse-resize;
}
