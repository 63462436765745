.usersGlobalContainer {
  position: relative;
  width: 100%;
  max-width: 75rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
}
.usersGlobalContainer > nav,
.usersGlobalContainer > .searchContainer {
  margin-bottom: 1rem;
}
.usersGlobalContainer > button {
  align-self: flex-start;
}
.addForm > form {
  /* width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.1rem;
  gap: 0.5rem; */
  background-color: var(--MDDprimaryTransparent);
}
/* .addForm > form > input, */
.userFormContainer > form > input {
  width: calc((100%) / 5);
  min-width: 8rem;
  background-color: transparent;
  color: var(--MDDbasic);
  border-radius: 0.5rem;
  border: 0.05rem solid var(--MDDbackground);
  outline: 0.05rem solid transparent;
  height: 2rem;
  padding: 0 0.75rem;
  font-family: var(--MDDfont);
}

/* .addForm > form > input:first-child, */
.usersLabelsContainer > p:first-child,
.userFormContainer > form > input:first-child,
.userFormContainer > div > p:first-child {
  min-width: 14rem;
}
.userActionsContainer {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
  margin: 0.5rem 0 1rem 0;
}
.userActionsContainer > div {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.5rem;
}
.usersLabelsContainer {
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 0.1rem solid var(--MDDbasic);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.usersLabelsContainer > p {
  width: calc((100%) / 5);
  min-width: 8rem;
  padding: 0 0.75rem;
}
.usersListContainer {
  width: 100%;
  height: calc(100vh - 25.5rem);
  overflow: visible;
}
.userFormContainer {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  overflow: visible;
}
.selectedUser {
  background-color: var(--MDDprimaryTransparent);
}
.userFormContainer > button:first-child {
  position: absolute;
  left: -2rem;
  border-radius: 0;
  width: 1.5rem;
  border: 0.1rem solid var(--MDDwhite);
  background-color: var(--MDDprimaryTransparent);
}
.addForm > button:first-child {
  background-color: transparent;
  color: var(--MDDbasic);
}
.addForm > button:first-child:hover {
  color: var(--MDDprimary);
}
.userFormContainer > form,
.userFormContainer > div {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.1rem;
  gap: 0.5rem;
  overflow: visible;
}
.userFormContainer > form > i,
.userFormContainer > div > i {
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0 0.25rem;
}
.userFormContainer > div > p {
  width: calc((100%) / 5);
  min-width: 8rem;
  background-color: transparent;
  color: var(--MDDbasic);
  height: 2rem;
  padding: 0.3rem 0.75rem 0.1rem 0.8rem;
}
.usersLabelsContainer > p:nth-child(2),
.usersLabelsContainer > p:nth-child(3),
.userFormContainer > div > p:nth-child(2),
.userFormContainer > div > p:nth-child(3),
.userFormContainer > form > input:nth-child(2),
.userFormContainer > form > input:nth-child(3) {
  width: 18%;
}
.usersLabelsContainer > p:nth-child(4),
.userFormContainer > div > p:nth-child(4),
.usersLabelsContainer > p:nth-child(6),
.userFormContainer > div > p:nth-child(6),
.userFormContainer > form > input:nth-child(6) {
  width: 12%;
}

/* .addForm > form > .usersSelect, */
.userFormContainer > form > .usersSelect {
  display: block;
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  color: var(--MDDbasic);
  padding: 0 0.5rem;
  height: 2rem;
  width: 12%;
  min-width: 8rem;
  border: 0.05rem solid transparent;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, var(--MDDwhite) 0%, var(--MDDwhite) 100%);
  cursor: pointer;
}
.addForm > form > input,
.addForm > form > .selectTeamsContainer {
  width: calc(((100% - 2.5rem) * 20) / 100);
}
.addForm > form > input:nth-child(2),
.addForm > form > input:nth-child(3) {
  width: calc(((100% - 2.5rem) * 18) / 100);
}
.addForm > form > .usersSelect,
.addForm > form > input:nth-child(6) {
  width: calc(((100% - 2.5rem) * 12) / 100);
}
.addForm > form > .usersSelect,
.selectedUser > form > .usersSelect {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(
      to bottom,
      var(--MDDprimaryTransparent) 0%,
      var(--MDDprimaryTransparent) 100%
    );
}
.addForm > form > .usersSelect::-ms-expand,
.userFormContainer > form > .usersSelect::-ms-expand {
  display: none;
}
.selectTeamsContainer {
  position: relative;
  height: 2rem;
  width: calc((100%) / 5);
  min-width: 8rem;
  border: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.selectTeamsContainer > button {
  width: 100%;
  min-width: 8rem;
  border: none;
}
.selectTeamsContainer > button:hover {
  color: var(--MDDbasic);
}
.selectTeamsContainer > .fullButton {
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  padding: 0 0.75rem;
  overflow: hidden;
  line-height: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selectTeamsContainer > .fullButton > i {
  color: #007cb2;
}
.userFormContainer > form > .openedSelect {
  overflow: visible;
  position: relative;
}
.userFormContainer > form > .openedSelect > div {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: fit-content;
  background-color: var(--MDDwhite);
  border: 0.1rem solid var(--MDDbasic);
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.userFormContainer > form > .openedSelect > div > div,
.userFormContainer > form > .openedSelect > div button {
  width: 100%;
}
.userFormContainer > form > .openedSelect > div button {
  border-radius: 0;
  justify-content: flex-start;
}
.userFormContainer > form > .openedSelect > div button.altButtonColor {
  text-align: left;
  padding-left: 2rem;
}
.topUsersContainer {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.topUsersContainer > div {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.usersCountsContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.usersCountsContainer > div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.5rem;
}

.usersGlobalContainer > form {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.usersGlobalContainer > form > h4 {
  margin-bottom: 1rem;
}
.usersGlobalContainer > form > label {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}
.usersGlobalContainer > form > label > input {
  width: 100%;
  font-size: var(--MDDmediumSize);
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border-radius: 0.25rem;
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
}
.usersGlobalContainer > form > label > input:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.usersGlobalContainer > form > button {
  align-self: flex-end;
}
.usersGlobalContainer > form > div:last-child {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
}
.usersGlobalContainer > form > div:last-child > button {
  margin-bottom: 1rem;
}
.adressContainer {
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  border: 0.1rem solid var(--MDDbasic);
}
.adressContainer:not(:first-of-type) {
  border-top: none;
}
