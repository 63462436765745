.datavizSidebar {
  position: fixed;
  top: 2rem;
  left: 0;
  height: calc(100vh - 2rem);
  width: 100%;
  max-width: 0px;
  z-index: 4;
  padding: 0;
  background-color: var(--MDDwhite);
  box-shadow: 0 0 0 var(--MDDdefault);
  overflow-x: hidden;
  overflow-y: scroll;
  transition: max-width 0.5s, box-shadow 0.5s;
}
.openedDatavizSidebar {
  max-width: 22rem;
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  box-shadow: 0.25rem 0 0.5rem var(--MDDdefault);
}
.datavizSidebar * {
  font-size: var(--MDDmediumSize);
}
.datavizSidebar > * {
  margin-bottom: 1rem;
}
.datavizSidebar > article:first-of-type,
.datavizSidebar > .optionsCategory > div {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.sidebarTitleToggle {
  width: 100%;
  max-width: 15rem;
  height: 2rem;
  border-radius: 1rem;
  border: 0.1rem solid var(--MDDbasic);
  gap: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
}
.sidebarTitleToggle > button,
.sidebarTitleToggle > .titleToggleBackground {
  width: 7.5rem;
}
/* .sidebarTitleToggle > button,
.sidebarTitleToggle > button:focus {
  width: fit-content;
  padding: 0 0.5rem;
} */
.titleToggleBackground.style ~ button:nth-child(2),
.titleToggleBackground.data ~ button:nth-child(3) {
  color: var(--MDDwhite);
}
.sidebarTitleToggle > .titleToggleBackground {
  position: absolute;
  height: 2rem;
  z-index: -1;
  border-radius: 1rem;
  background-color: var(--MDDbasic);
  transition: left 0.5s, background-color 0.5s;
}
.datavizSidebar > .optionsCategory {
  width: 100%;
  height: fit-content;
  max-height: 2.2rem;
  min-height: 2.2rem;
  padding: 0 0 0.5rem 0.5rem;
  overflow: hidden;
  transition: max-height 0.5s;
}
.datavizSidebar > .optionsCategory > div:first-of-type {
  height: 2.2rem;
  cursor: pointer;
  background-color: var(--MDDbackground);
  margin-bottom: -0.25rem;
  border-radius: 0.5rem;
  padding-right: 0.5rem;
  overflow-y: hidden;
}
.datavizSidebar > .optionsCategory > div:first-of-type > button {
  transform: rotate(0deg);
  transition: transform 0.5s;
}
.datavizSidebar > .openedCategory > div:first-of-type > button {
  transform: rotate(180deg);
  transition: transform 0.5s;
}
.datavizSidebar > .openedCategory {
  max-height: 20rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.25rem;
}
.datavizSidebar > .optionsCategory > p {
  width: 100%;
  text-align: left;
  background-color: var(--MDDbackgroundTransparent);
  height: 2rem;
  padding: 0.25rem 0;
}
.datavizSidebar > .optionsCategory > div:not(:first-of-type) {
  gap: 1rem;
  padding: 0.1rem;
  width: 100%;
  height: 2.2rem;
  overflow: hidden;
}
.datavizSidebar > .optionsCategory > div:not(:first-of-type) > label {
  width: calc(40% - 0.5rem);
  text-align: left;
}
.datavizSidebar > .optionsCategory > div:not(:first-of-type) > input,
.datavizSidebar > .optionsCategory > div:not(:first-of-type) > select {
  width: calc(60% - 0.5rem);
  background-color: transparent;
  color: var(--MDDbasic);
  border-radius: 0.25rem;
  border: none;
  outline: 0.05rem solid transparent;
  height: 2rem;
  padding: 0 0.75rem;
}
.datavizSidebar > .optionsCategory > div.datavizInput > input:focus,
.datavizSidebar > .optionsCategory > div.datavizInput > select:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.datavizSidebar > .optionsCategory > div.datavizInput > input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.datavizSidebar > .optionsCategory > .datavizSwitch:not(.datavizColorPicker) {
  justify-content: flex-start;
  gap: 1.75rem;
}
.datavizSwitch > div {
  width: 100%;
  max-width: calc(60% - 1.25rem);
  height: 2rem;
  border-radius: 1rem;
  border: 0.1rem solid var(--MDDbasic);
  gap: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
}
.datavizSwitch > div > button,
.datavizSwitch > div > .datavizSwitchBackground {
  width: 50%;
}
.datavizSidebar > .optionsCategory > .iconsSwitch {
  justify-content: flex-start;
}
.iconsSwitch > div > button,
.iconsSwitch > div > .datavizSwitchBackground {
  width: 2rem;
}
.datavizSwitchBackground.active ~ button:nth-child(3),
.datavizSwitchBackground.inactive ~ button:nth-child(2) {
  color: var(--MDDwhite);
}
.iconsSwitch .datavizSwitchBackground.position0 ~ button:nth-child(2),
.iconsSwitch .datavizSwitchBackground.position1 ~ button:nth-child(3),
.iconsSwitch .datavizSwitchBackground.position2 ~ button:nth-child(4),
.iconsSwitch .datavizSwitchBackground.position3 ~ button:nth-child(5),
.iconsSwitch .datavizSwitchBackground.position4 ~ button:nth-child(6),
.iconsSwitch .datavizSwitchBackground.position5 ~ button:nth-child(7),
.iconsSwitch .datavizSwitchBackground.position6 ~ button:nth-child(8) {
  color: var(--MDDwhite);
}
.datavizSwitch > div > .datavizSwitchBackground {
  position: absolute;
  height: 2rem;
  z-index: -1;
  border-radius: 1rem;
  background-color: var(--MDDbasic);
  transition: left 0.5s, background-color 0.5s;
}
.datavizSidebar > .optionsCategory > .datavizColorPicker {
  position: relative;
  /* overflow-y: visible; */
  justify-content: flex-start;
}
.datavizSidebar > .optionsCategory > div.datavizColorPicker > input {
  max-width: calc(60% - 3.5rem);
}
.datavizColorPicker > button {
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid var(--MDDdefault);
  border-radius: 1rem;
}
.datavizColorPicker > .chrome-picker {
  display: none;
  position: fixed;
  top: 10rem;
  left: 23rem;
  z-index: 2;
  height: fit-content;
}

.datavizInput input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  height: 1rem;
}
.datavizInput input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0.1rem solid var(--MDDprimary);
  height: 1rem;
  width: 1rem;
  border-radius: 0.55rem;
  background-color: var(--MDDprimary);
  cursor: pointer;
  margin-top: -0.45rem;
}
.datavizInput input[type="range"]::-moz-range-thumb {
  border: 0.1rem solid var(--MDDprimary);
  height: 1rem;
  width: 1rem;
  border-radius: 0.55rem;
  background-color: var(--MDDprimary);
  cursor: pointer;
}
.datavizInput input[type="range"]::-ms-thumb {
  border: 0.1rem solid var(--MDDprimary);
  height: 1rem;
  width: 1rem;
  border-radius: 0.55rem;
  background-color: var(--MDDprimary);
  cursor: pointer;
}

.datavizInput input[type="range"]:focus {
  outline: none;
}

.datavizInput input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.1rem;
  cursor: pointer;
  background-color: var(--MDDprimary);
  border-radius: 0.1rem;
  border: 0 solid transparent;
}

.datavizInput input[type="range"]:focus::-webkit-slider-runnable-track {
  background-color: var(--MDDprimary);
}

.datavizInput input[type="range"]::-moz-range-track {
  width: 100%;
  height: 0.1rem;
  cursor: pointer;
  background-color: var(--MDDprimary);
  border-radius: 0.1rem;
  border: 0 solid transparent;
}

.datavizInput input[type="range"]::-ms-track {
  width: 100%;
  height: 0.1rem;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
.datavizInput input[type="range"]::-ms-fill-lower {
  background-color: var(--MDDprimary);
  border: 0 solid transparent;
  border-radius: 2.6px;
}
.datavizInput input[type="range"]:focus::-ms-fill-lower {
  background-color: var(--MDDprimary);
}
.datavizInput input[type="range"]::-ms-fill-upper {
  background-color: var(--MDDprimary);
  border: 0 solid transparent;
  border-radius: 2.6px;
}
.datavizInput input[type="range"]:focus::-ms-fill-upper {
  background-color: var(--MDDprimary);
}
.datavizSidebar > .deleteDashboardContainer {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}
.datavizSidebar > .deleteDashboardContainer > button {
  background-color: var(--MDDdataviz);
  border: 0.1rem solid var(--MDDdataviz);
}
.datavizSidebar > .deleteDashboardContainer > button:hover {
  background-color: var(--MDDwhite);
  border: 0.1rem solid var(--MDDwhite);
  color: var(--MDDdataviz);
}
