.datavizHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 2.25rem;
  background-color: var(--MDDdataviz);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.datavizHeader button,
.datavizHeader > input {
  color: var(--MDDwhite);
}
.datavizHeader > input {
  background-color: transparent;
  border: none;
  font-size: var(--MDDlargeSize);
}
.datavizHeader > article {
  width: 100%;
  max-width: 8rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}
.datavizHeader > article:first-of-type {
  margin-left: 0.5rem;
  justify-content: flex-start;
}
.datavizHeader > article:last-of-type {
  margin-right: 0.5rem;
}
.datavizHeader > article:first-of-type > button {
  border: 0.1rem solid var(--MDDwhite);
}
