.noPageGlobalContainer {
  width: 100%;
  height: calc(100vh - 4rem);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.noPageGlobalContainer > img {
  width: 100%;
  max-width: 30rem;
}
.noPageGlobalContainer > a {
  color: var(--MDDprimary);
  transition: color 0.5s;
}
.noPageGlobalContainer > a:hover {
  color: var(--MDDbasic);
}
