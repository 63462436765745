.docsImportContainer {
  width: 100%;
  row-gap: 0.5rem;
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
  margin-top: 1rem;
}
.docsImportContainer > div {
  height: 100%;
  gap: 1rem;
}
.docsImportContainer > .inputsContainer {
  max-width: calc(100% - 12rem);
}
.docsImportContainer > .inputsContainer > p,
.docsImportContainer > .inputsContainer > div > p {
  padding-top: 0.3rem;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.docsImportContainer > .inputsContainer > div {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.docsImportContainer a:hover {
  color: var(--MDDprimary);
}
