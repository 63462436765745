.footerContainer {
  background-color: var(--MDDbasic);
  height: 2rem;
  width: 100%;
  padding: 0 1rem 0 4rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: center;
  gap: 0.25rem;
  flex-shrink: 0;
  /* z-index: 5; */
}
.footerContainer > p {
  color: var(--MDDbackground);
}
.footerContainer > a {
  color: var(--MDDprimary);
}
@media only screen and (max-width: 760px) {
  .footerContainer {
    height: 3rem;
    padding: 0.5rem;
  }
}
