.datavizSidebar > .optionsCategory > .textAreaInput:not(:first-of-type) {
  height: fit-content;
}
.datavizSidebar
  > .optionsCategory
  > .textAreaInput:not(:first-of-type)
  > label {
  width: 100%;
}
.datavizSidebar
  > .optionsCategory
  > .textAreaInput:not(:first-of-type)
  > .codeEditorContainer {
  min-height: 20rem;
  height: 20rem;
  font-size: var(--MDDsmallSize);
}
.datavizSidebar
  > .optionsCategory
  > .textAreaInput:not(:first-of-type)
  > .codeEditorContainer
  .codeInput,
.datavizSidebar
  > .optionsCategory
  > .textAreaInput:not(:first-of-type)
  > .codeEditorContainer
  > .codeOutput {
  min-height: 20rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  border: none;
  font-family: "Source Code Pro", monospace;
  font-weight: 300;
  white-space: pre-wrap;
  line-height: 1.5em;
  word-wrap: break-word;
  margin: 0;
  border-radius: 0.5rem;
  outline: none;
  font-size: var(--MDDsmallSize);
}

.datavizSidebar
  > .optionsCategory
  > .textAreaInput:not(:first-of-type)
  > .fullScreenCodeEditor
  .codeInput,
.datavizSidebar
  > .optionsCategory
  > .textAreaInput:not(:first-of-type)
  > .fullScreenCodeEditor
  > .codeOutput {
  min-height: calc(100vh - 3.5rem);
  height: calc(100vh - 3.5rem);
}

.datavizSidebar
  > .optionsCategory
  > .textAreaInput:not(:first-of-type)
  > .codeEditorContainer
  > .codeOutput
  > code,
.datavizSidebar
  > .optionsCategory
  > .textAreaInput:not(:first-of-type)
  > .codeEditorContainer
  > .codeOutput
  > code
  > span {
  font-size: var(--MDDsmallSize);
}
