.detailedCardContainer {
  z-index: 5;
  background-color: transparent;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  height: 100%;
  min-height: 25rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0;
}
.slidersContainer,
.detailedCardContainer > form > .formInnerContainer,
.technicalRessourcesContainer,
.nodesListContainer,
.packagesGlobalContainer > section,
.docsImportContainer,
.codeNameContainer,
.endpointsModal,
.streamVariablesContainer {
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
}
.detailedCardContainer > form,
.detailedCardContainer > form > .formInnerContainer > div,
.nodesListContainer,
.packagesTreeContainer,
.packagesImportContainer > div,
.docsImportContainer > div,
.codeNameContainer > div {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detailedCardContainer > form > .formInnerContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.topEditcardContainer {
  width: 100%;
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  padding: 0 0.25rem;
  overflow-x: visible;
}
.topEditcardContainer > div {
  max-width: calc(100% - 12rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row nowrap;
}
.ressourcesGlobalContainer,
.packagesGlobalContainer,
.docsGlobalContainer,
.packagesImportContainer,
.docsImportContainer,
.codeGlobalContainer,
.codeNameContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.detailedCardContainer > .topEditcardContainer > div > button {
  width: 5rem;
}
.detailedCardContainer > .topEditcardContainer > div > .streamDocButton {
  width: 9rem;
}
.detailedCardContainer
  > .topEditcardContainer
  > .userNavContainer
  > button:nth-child(2),
.detailedCardContainer > .topEditcardContainer > div > button:last-child {
  width: 8rem;
}
.detailedCardContainer > form {
  width: 100%;
  align-items: center;
}
.detailedCardContainer > form > .formInnerContainer {
  border-radius: 0.5rem;
  width: calc(100% - 0.5rem);
  background-color: var(--MDDwhite);
  margin: 1rem 0 4rem 0;
}
.detailedCardContainer > form > .formInnerContainer > div {
  width: 100%;
  height: 100%;
  gap: 1rem;
}
.labelsContainer > label,
.labelsContainer > p {
  width: 100%;
  height: 2rem;
  max-width: 9rem;
  text-align: right;
  padding-top: 0.3rem;
  color: var(--MDDwhite);
}
.inputsContainer input,
.inputsContainer > textarea,
.detailedCardContainer > form > .formInnerContainer > .inputsContainer > p,
.inputsContainer > button,
.inputsContainer > select,
.tagsContainer {
  width: 100%;
  font-size: var(--MDDmediumSize);
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border-radius: 0.25rem;
}
.inputsContainer input:focus,
.inputsContainer > textarea:focus,
.inputsContainer > select:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.inputsContainer input,
.inputsContainer > textarea,
.inputsContainer > select,
.tagsContainer {
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
}
.inputsContainer input,
.inputsContainer > select {
  height: 2rem;
  padding: 0 0.75rem;
}
.inputsContainer > textarea {
  height: 16rem;
  padding: 0.5rem 0.75rem;
  resize: none;
}
/* .tagsContainer {
  gap: 0.5rem;
  padding: 0 0.5rem;
}
.inputsContainer > .tagsContainer > input {
  border: none;
  width: 100%;
}
.tagsContainer > .singleTag {
  width: max-content;
  height: 1.5rem;
  line-height: var(--MDDsmallSize);
  padding: 0 0.5rem;
  font-size: var(--MDDsmallSize);
  background-color: rebeccapurple;
  color: var(--MDDwhite);
  border-radius: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  overflow-x: visible;
}

.tagsContainer > .singleTag > i {
  font-size: 0.5rem;
  cursor: pointer;
  color: transparent;
  transition: color 0.5s;
}
.tagsContainer > .singleTag:hover > i {
  color: var(--MDDwhite);
}
.tagsContainer > .singleTag > i:hover {
  color: var(--MDDprimary);
} */
.detailedCardContainer
  > form
  > .formInnerContainer
  > .labelsContainer
  > label:nth-child(3) {
  height: 16rem;
  padding-top: 0.4rem;
}
.detailedCardContainer > form > .formInnerContainer > .inputsContainer > p {
  padding: 0.3rem 0.75rem 0 0.75rem;
  height: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.labelsContainer {
  background-color: var(--MDDbasic);
  width: 100%;
  max-width: 10rem;
  gap: 0.75rem;
  padding: 1rem 0.5rem 1rem 1rem;
}
.inputsContainer {
  background-color: transparent;
  width: 100%;
  max-width: calc(100% - 10rem);
  gap: 1rem;
  padding: 1rem 1rem 1rem 0.5rem;
}
.formInnerContainer > .inputsContainer > div {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.formInnerContainer > .inputsContainer > div:last-child {
  height: 2rem;
}
.formInnerContainer > .inputsContainer > div:last-child > p {
  cursor: copy;
}
.detailedCardContainer
  > form
  > .formInnerContainer
  > .inputsContainer
  > button {
  height: 2rem;
  padding: 0 0.75rem;
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.editBottomButtonsContainer,
.detailedBottomButtonsContainer {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1rem;
}
.editBottomButtonsContainer > div {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}
.packagesGlobalContainer,
.docsGlobalContainer,
.codeGlobalContainer {
  width: 100%;
  gap: 1rem;
  padding: 0 0.25rem 0.25rem 0.25rem;
}
@media only screen and (max-width: 760px) {
  .topEditcardContainer {
    flex-flow: row wrap;
  }
  .topEditcardContainer > div {
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 1rem;
  }
}
