.mddLoaderContainer {
  position: fixed;
  top: calc(50vh - 6rem);
  left: calc(50vw - 6rem);
  z-index: 5;
  width: 12rem;
  height: 12rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.mddLoaderContainer > i {
  font-size: 6rem;
  color: var(--MDDdefault);
  position: relative;
  cursor: progress;
  animation: loadingIcon 1.5s infinite linear;
}
@keyframes loadingIcon {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  40% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
