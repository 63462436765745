.logsDatesModal {
  position: absolute;
  top: 2.5rem;
  right: 1rem;
  z-index: 8;
  width: 100%;
  max-width: 25rem;
  height: 0rem;
  background-color: var(--MDDwhite);
  transition: height 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.openedLogsModal {
  height: 30.5rem;
}
.logsDatesModal > .calendarContainer {
  position: static;
}
.logsDatesModal > .timePickerContainer {
  height: 10rem;
  width: 100%;
  max-width: 25rem;
  margin-top: 0.5rem;
  border: 0.1rem solid var(--MDDbasic);
  background-color: var(--MDDwhite);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
}
.logsDatesModal > .timePickerContainer label {
  padding: 0 0.5rem 0.1rem 0.5rem;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}
.logsDatesModal input,
.logsHeader > article:nth-child(2) > input {
  width: 100%;
  font-size: var(--MDDmediumSize);
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border-radius: 0.25rem;
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
  height: 2rem;
  padding: 0 0.75rem;
}
.logsDatesModal input:focus,
.logsHeader > article:nth-child(2) > input:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.logsDatesModal button {
  margin-right: 0.5rem;
  align-self: flex-end;
}
