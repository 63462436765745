.homeCardsContainer {
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, max-content));
  justify-content: center;
  row-gap: 1rem;
  column-gap: 2rem;
}
.homeCardOuterLink {
  border: none;
  width: 17rem;
  height: 25.5rem;
  box-shadow: var(--MDDwhite) 0 0 0;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: box-shadow 0.25s;
}
.homeCardOuterLink:first-child {
  background-color: var(--MDDbasic);
}
.homeCardOuterLink:nth-child(2) {
  background-color: var(--MDDsubscriptions);
}
.homeCardOuterLink:nth-child(3) {
  background-color: var(--MDDdataviz);
}
.homeCardOuterLink:nth-child(4) {
  background-color: var(--MDDexports);
}
.homeCardOuterLink:nth-child(5) {
  background-color: var(--MDDusers);
}
.homeCardOuterLink:hover {
  box-shadow: var(--MDDdarkShadow) 0.5rem 0.5rem 0.5rem,
    var(--MDDdarkShadow) -0.5rem -0.5rem 0.5rem,
    var(--MDDdarkShadow) 0.5rem -0.5rem 0.5rem,
    var(--MDDdarkShadow) -0.5rem 0.5rem 0.5rem;
}

.homeCard {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
}

.homeCard > p,
.homeCard > h4 {
  color: var(--MDDwhite);
  padding: 0.5rem 0;
}
.homeCard > h4 {
  font-weight: var(--MDDsemiBold);
  font-size: var(--MDDlargeSize);
}
.homeCard > i {
  color: var(--MDDwhite);
  margin-top: 2rem;
}
