.dashboardWithLargeSideBar.logsDashboardContainer {
  margin-left: 8rem;
}
.logsGlobalContainer {
  width: 100%;
  background-color: var(--MDDwhite);
  padding: 0;
  z-index: 0;
}
.logsHeader,
.logsHeader > article:not(.logsDatesModal) {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}
.logsHeader {
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  overflow: visible;
  height: 6.5rem;
  padding-right: 1rem;
}
.logsHeader > article:not(.calendarContainer) {
  min-height: 2rem;
  width: fit-content;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0;
}
.logsHeader > article:first-child {
  height: 2rem;
  max-width: 25rem;
  flex-flow: row nowrap;
}
.logsHeader > article:first-child > p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.logsHeader > article > i {
  font-size: 1.5rem;
}
.logsHeader > article > button:first-of-type,
.logsHeader > article > button:nth-of-type(2) {
  border-radius: 0;
}
.logsHeader > article > .secondaryButton:hover {
  border: 0.1rem solid var(--MDDbackground);
}
.logsHeader > article > button:first-child:not(.secondaryButton),
.logsHeader > article > button:nth-child(2):not(.secondaryButton) {
  background-color: #34d4fc;
}
.logsHeader > article > button:last-of-type {
  width: 9rem;
}
.logsHeader > article > button:last-child {
  margin-left: -0.5rem;
}
.timeZoneToggle {
  border-radius: 1rem;
  border: 0.1rem solid var(--MDDbasic);
  grid-gap: 0;
  gap: 0;
  padding: 0;
  position: relative;
}
.logsHeader .exportNavBackground.off ~ button:nth-child(2),
.logsHeader .exportNavBackground.live ~ button:nth-child(3),
.logsHeader .exportNavBackground.default ~ button:nth-child(2),
.logsHeader .exportNavBackground.locale ~ button:nth-child(3) {
  color: var(--MDDwhite);
}
#linesInput {
  max-width: 5rem;
}
.logsBody {
  padding-top: 0rem;
  width: 100%;
}
.logsBody > div {
  position: relative;
  width: 100%;
  padding-top: 2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

}
.logsNode {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 15rem;
  overflow: none;
  padding: 0.5rem;
}
.logsNode > div:nth-child(2) {
  position: absolute;
  top: 3rem;
  left: 0;
  padding: 0;
  width: 100%;
  min-height: 14rem;
}
.reloadLogsContainer {
  width: 100%;
  height: 4rem;
}
.logsNode .logsLabels {
  position: sticky;
  top: -0.5rem;
  left: 0;
  background-color: var(--MDDwhite);
  height: 2rem;
  padding-top: 0.5rem;
  z-index: 6;
}
.logsNode div:not(.logsLabels) > p {
  font-weight: 100;
  font-size: var(--MDDsmallSize);
}
.logsNode > div:first-child {
  width: 100%;
}
.LogsNode .info {
  font-size: 1.1rem;
  font-weight: 200;
  font-family: "Source Code Pro";
  text-align: center;
  width: 100%;
}
section.search > div {
  margin-top: 3rem;
  width: 100%;
}
input.searchRegex {
  width: 100%;
  
}
.logsHeader > div:first-child {
  width: calc(100% - 5rem);
  margin-bottom: 2rem;
  font-size: 1.1rem;
}
.logsHeader .lambda-pattern {
  width: 5rem;
  display: inline;
  margin-right: 1rem;
}
.logsHeader .lambda-path {
  font-family: "Open Sans";
  font-size: 1.1rem;
  font-weight: 200;
  letter-spacing: 0.08rem;
  text-overflow: ellipsis;
  display: inline;
}
.logHeader {
  display: none;
}

.logLine {
  width: 100%;
  min-height: 1.4rem;
  background: #f7f7f7 ;
  margin-top: .1rem;
  padding: .2rem;
}
.logLine > .line {
  height: 1.4rem;
}
.logLine > .line:hover {
  background-color: #c2ebff;
}
.logLine > .line > div {
  text-align: left;
  padding-right: 1rem;
  width: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
  font-size: .9rem;
  font-weight: 200;
  font-family: "Source Code Pro";
  letter-spacing: -0.03rem;
}
.logLine > .detail {
  background-color: #f5ffff;
}
.logLine > .detail > .message> pre {
  font-size: 0.9rem;
  font-weight: 100;
  line-height: 1.1rem;
  padding: 1rem;
  text-align: left;
  overflow-x: auto;
  overflow-y: auto;
  font-family: "Source Code Pro";
  max-height: 90vh;
  line-height: 1.4rem;
}

.logLine > .detail > .fields  {
  padding: 1rem;
}
.logLine > .detail > .fields > table {
  font-size: 1rem;
  width: 100%;
}
.logLine > .detail > .fields > table > tr {
  margin-bottom: 1rem;
}
.logLine > .detail > .fields > table > tr > td:first-child {
  font-family: "Source Code Pro";
  font-size: 1rem;
  text-align: left;  
  width: 300px;
  padding-left: 1rem;
}
.logLine > .detail > .fields > table > tr > td:nth-child(2) {
  font-family: "Source Code Pro";
  font-size: 1rem;
  text-align: left;  
  border-bottom: solid .1rem;
  border-color: #e4e4e4;
  padding-top: .8rem;
  padding-bottom: .8rem;
  max-width: 8rem;
  text-overflow: unset;
  word-wrap: anywhere;
  padding: 1rem;
}

.logsContent {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  height: 1.4rem;
  background: #f7f7f7 ;
  margin-top: .1rem;
  padding: .2rem;
}
.logsContent:not(.logsLabels):hover {
  background-color: var(--MDDprimaryTransparent);
}
.logLine .line .statusWarning {
  color: var(--MDDstatusWarning);
}
.logLine .line .statusAlert {
  color: var(--MDDstatusAlert);
}
.logLine .line .statusFatal {
  color: var(--MDDstatusFatal);
}
.logsContent p {
  position: relative;
  text-align: left;
  padding-right: 1rem;
  width: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

}
.logLine > .line > div:first-of-type,
.logHeader > .line > div:first-of-type {
  width: 15rem;
}
.logLine > .line > div:nth-of-type(2),
.logHeader > .line > div:nth-of-type(2) {
  width: 6rem;
}
.logLine > .line > div:nth-of-type(3),
.logHeader > .line > div:nth-of-type(3) {
  min-width: 7rem;
}
.logLine > .line > div:nth-of-type(4),
.logHeader > .line > div:nth-of-type(4) {
  min-width: 13rem;
}
.logLine > .line > div:nth-of-type(5),
.logHeader > .line > div:nth-of-type(5) {
  max-width: calc(100% - 40rem);
}


.logsNode .logDetail {
  display: none;
}
#dateFromInput {
  width: 10rem;
}

.fullScreenNode i {
  animation: none;
}
.logsNode > button {
  position: sticky;
  top: 0;
  left: calc(100% - 2rem);
  background-color: var(--MDDwhite);
  z-index: 7;
}
.logInfo {
  width: 9rem;
  height: 9rem;
  word-break: break-all;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
@media only screen and (min-width: 1668px) {
  .logsHeader {
    height: 4.5rem;
  }
}
