.solarGlobalContainer {
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  gap: 0;
}
.solarHeader {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 0.1rem solid var(--MDDdataviz);
  z-index: 2;
}
.solarHeader > .mddLongButton {
  width: 33%;
  border-radius: 0;
}
.solarHeader > .mddLongButton.basicButtonColor,
.solarHeader > .mddLongButton.basicButtonColor:hover {
  color: var(--MDDwhite);
  background-color: var(--MDDdataviz);
  border: 0.1rem solid var(--MDDdataviz);
}
.solarHeader > .mddLongButton:not(.basicButtonColor) {
  background-color: var(--MDDwhite);
}
.solarBody {
  width: 100%;
  min-height: 20rem;
  padding: 0.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
}
.productionBody {
  flex-flow: column nowrap;
  align-items: center;
  gap: 1rem;
}
.indicatorsBody > button:not(.mddButton) {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 0;
  background-color: transparent;
  border: none;
}
.indicatorsBody > .indicatorCard {
  max-width: 15rem;
  height: 7rem;
  background-color: var(--MDDdataviz);
  border: 0.25rem solid var(--MDDdataviz);
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  transition: background-color 0.5s;
}
.indicatorCard:hover {
  background-color: var(--MDDwhite);
}
.indicatorsBody > button > p {
  font-size: var(--MDDlargeSize);
  transition: color 0.5s;
}
.indicatorsBody > button:not(.indicatorCard):hover > p {
  color: var(--MDDprimary);
}
.indicatorCard > p {
  width: 100%;
  color: var(--MDDwhite);
}
.indicatorCard:hover > p {
  color: var(--MDDdataviz);
}
.indicatorCard > p:first-of-type {
  font-size: 3rem;
}
.indicatorCard > p > span {
  font-size: var(--MDDlargeSize);
}
.indicatorCard > p:last-of-type {
  font-size: var(--MDDmediumSize);
}
.solarBody > .mddButton {
  margin: 2rem auto 0 auto;
}
.solarBody > .solarGraph {
  width: 100%;
  height: 30rem;
}
.solarNavBackground {
  height: 2rem;
  width: 7rem;
  background-color: var(--MDDbasic);
  position: absolute;
  left: 0;
  z-index: -1;
  border-radius: 1rem;
  transition: left 0.5s;
}
.solarNavBackground.live ~ button:nth-child(2),
.solarNavBackground.history ~ button:nth-child(3) {
  color: var(--MDDwhite);
}
