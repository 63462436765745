.hotelsDashboard {
  align-content: flex-start;
}
.hotelsDashboard > nav {
  width: 100%;
}
.bi-sun-fill {
  color: var(--MDDstatusWarning);
}
.bi-moon-fill {
  color: var(--MDDdefault);
}
.hotelsGlobalContainer {
  position: relative;
  width: 100%;
  gap: 2rem;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, max-content));
  justify-content: center;
}
.hotelCard {
  width: 100%;
  max-width: 20rem;
  height: 8rem;
  background-color: var(--MDDbasic);
  border-radius: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}
.hotelCard p {
  color: var(--MDDwhite);
}
.hotelCard > h3:first-of-type {
  font-size: var(--MDDlargeSize);
  color: var(--MDDwhite);
  font-family: "Alfa Slab One", var(--MDDfont), sans-serif;
}
.hotelCard > div:nth-child(2) {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
}
.hotelCard > div:nth-child(2) > div {
  width: 4rem;
}
.hotelCard > div:nth-child(2) > div:first-of-type > p {
  width: 2rem;
  height: 2rem;
  background-color: var(--MDDwhite);
  border: 0.1rem solid var(--MDDstatusAlert);
  color: var(--MDDstatusAlert);
  border-radius: 1rem;
  text-align: center;
  padding-top: 0.25rem;
}
.hotelCard > div:nth-child(2) > div:last-child {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.hotelCard > div:nth-child(2) > div:last-child > i {
  font-size: 1.5rem;
}
.hotelDetailsContainer {
  width: 100%;
  height: calc(100vh - 4rem);
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.hotelDetailsContainer > section {
  width: 100%;
  border: 0.5rem solid var(--MDDbasic);
  border-radius: 1.5rem;
}
.hotelDetailsContainer > .hotelTitleContainer {
  max-width: calc(100vw - 19.5rem);
  height: 7rem;
  background-color: var(--MDDbasic);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.hotelTitleContainer > .altButtonColor {
  color: var(--MDDwhite);
}
.hotelTitleContainer > .altButtonColor:hover {
  color: var(--MDDprimary);
}
.hotelTitleContainer > h1 {
  text-align: center;
  color: var(--MDDwhite);
  font-size: 3rem;
}
.hotelDetailsContainer > .hotelLocalInfos {
  max-width: 16.5rem;
  height: 7rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0.25rem;
  overflow: hidden;
}
.hotelDetailsContainer > .hotelLocalInfos > p {
  width: 100%;
  text-align: center;
  font-size: var(--MDDlargeSize);
}
.hotelDetailsContainer > .hotelLocalInfos > div {
  width: fit-content;
}
.hotelDetailsContainer > .hotelLocalInfos > div:first-of-type {
  padding-top: 0.75rem;
}
.hotelDetailsContainer > .hotelLocalInfos > div:first-of-type > div {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-left: 0.5rem;
}
.hotelDetailsContainer > .hotelLocalInfos > div:first-of-type > div > p {
  text-align: left;
}
.hotelDetailsContainer > .hotelLocalInfos > div:first-of-type .bi-moon-fill {
  font-size: 0.75rem;
  padding-left: 0.25rem;
}
.hotelDetailsContainer > .hotelLocalInfos > div:nth-of-type(2) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  padding-right: 0.5rem;
}
.hotelDetailsContainer > .hotelLocalInfos > div:nth-of-type(2) > p,
.hotelDetailsContainer > .hotelLocalInfos > div:nth-of-type(2) > i {
  font-size: 3rem;
}
.hotelRoomsContainer,
.hotelTypesContainer {
  height: calc(100vh - 12rem);
  min-height: 10rem;
}
.hotelRoomsContainer {
  max-width: calc(100vw - 19.5rem);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, max-content));
  justify-content: center;
  align-content: flex-start;
  row-gap: 1rem;
  column-gap: 1rem;
  padding: 1rem;
}
.hotelSingleRoom {
  height: 4rem;
  width: 12rem;
  background-color: var(--MDDbasic);
  border-radius: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  cursor: pointer;
  box-shadow: var(--MDDwhite) 0 0 0;
  transition: box-shadow 0.25s;
}
.hotelSingleRoom:hover {
  box-shadow: var(--MDDdarkShadow) 0.25rem 0.25rem 0.25rem,
    var(--MDDdarkShadow) -0.25rem -0.25rem 0.25rem,
    var(--MDDdarkShadow) 0.25rem -0.25rem 0.25rem,
    var(--MDDdarkShadow) -0.25rem 0.25rem 0.25rem;
}
.hotelSingleRoom p {
  color: var(--MDDwhite);
}
.hotelSingleRoom > div:first-of-type {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.hotelDetailsContainer input {
  overflow: hidden;
}
.hotelSingleRoom input[type="number"],
.hotelSingleType > div:nth-of-type(2) > input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
  width: 2rem;
  background-color: transparent;
  color: var(--MDDwhite);
  border: none;
  border-radius: 0.5rem;
}
.hotelSingleRoom input[type="number"]::-webkit-inner-spin-button,
.hotelSingleRoom input[type="number"]::-webkit-outer-spin-button,
.hotelSingleType input[type="number"]::-webkit-inner-spin-button,
.hotelSingleType input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hotelSingleRoom > div:first-of-type > p {
  margin-left: -0.5rem;
}
.hotelSingleRoom > div:first-of-type > i {
  background-color: var(--MDDwhite);
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  text-align: center;
  border: 0.15rem solid var(--MDDstatusAlert);
  font-size: 1.25rem;
  padding-top: 0.125rem;
}
.hotelTypesContainer {
  max-width: 16.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.hotelTypesContainer > p {
  width: 100%;
  text-align: center;
  font-size: var(--MDDlargeSize);
}
.hotelSingleType {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 0.5rem 1rem 0;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.25s;
}
.hotelSingleType:hover {
  background-color: var(--MDDbackground);
}
.hotelSingleType > div:first-of-type {
  height: 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.hotelSingleRoom > img,
.hotelSingleType > div:first-of-type > img {
  height: 1.5rem;
}
.hotelSingleType > div:nth-of-type(2) {
  height: 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}
.hotelSingleType > div:nth-of-type(2) > input[type="number"] {
  color: var(--MDDbasic);
}
.hotelSingleType > div:nth-of-type(2) > p {
  margin-left: -1rem;
}
.hotelAlertsContainer {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}
