.profileWidget {
  position: fixed;
  top: 4.5rem;
  right: 0;
  z-index: 18;
  background-color: #76828c;
  height: 3rem;
  min-height: 3rem;
  width: 3rem;
  border-radius: 0.5rem 0 0 0.5rem;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  transition: width 0.5s 0.5s, height 0.5s, min-height 0.5s;
}
.profileWidget:hover {
  width: 10rem;
  height: fit-content;
  min-height: 10rem;
  max-height: 17rem;
  transition: width 0.5s, height 0.5s 0.5s, min-height 0.5s 0.5s;
}
.profileWidget > div:first-child {
  height: 3rem;
  padding: 0.5rem 0.25rem;
  background-color: var(--MDDbasic);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.profileWidget > div:first-child > button:first-child {
  transform: rotate(90deg);
  transition: transform 0.5s;
}
.profileWidget:hover > div:first-child > button:first-child {
  transform: rotate(0deg);
}
.profileWidget > div:first-child > button:last-child {
  display: none;
}
.profileWidget:hover > div:first-child > button:last-child {
  display: initial;
}
.profileWidget > div:nth-child(2) {
  position: relative;
  height: fit-content;
  min-height: 7rem;
  max-height: 14rem;
  width: 100%;
  padding: 0 0.5rem;
  overflow: hidden;
  overflow-y: scroll;
  animation: widgetTeams 1s ease-out 0s 1;
}
@keyframes widgetTeams {
  0% {
    height: 0rem;
    min-height: 0rem;
  }
  50% {
    height: 0rem;
    min-height: 0rem;
  }
  100% {
    height: fit-content;
    min-height: 7rem;
  }
}
.profileWidget > div:nth-child(2) > button {
  width: 9rem;
  justify-content: flex-start;
  color: var(--MDDwhite);
}
.profileWidget > div:nth-child(2) > button:hover {
  color: var(--MDDprimary);
}
.profileWidget > div:nth-child(2) > .activeTeamButton:hover {
  color: var(--MDDwhite);
}
.profileWidget > div:nth-child(2) > .activeTeamButton > i {
  color: var(--MDDstatusOk);
}
.profileMenuContainer {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background-color: var(--MDDmodalBackground);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.profileMenuContainer > section {
  width: 100%;
  max-width: 45rem;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: calc(100vh - 2rem);
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
  position: absolute;
  top: 2.5rem;
}
.profileMenuContainer > section > article {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content;
  max-height: calc(100vh - 2rem);
}
.profileLabels {
  width: 100%;
  max-width: 15rem;
  padding: 3.85rem 0.5rem 0.5rem 1rem;
  background-color: var(--MDDbasic);
  gap: 0.5rem;
  position: relative;
}
.profileLabels > label {
  color: var(--MDDwhite);
  width: 100%;
  text-align: right;
  height: 2rem;
}
.profileLabels > .version {
  color: #fff;
  font-size: 1rem;
  position: absolute;
  top: 1rem;
  left: calc(50% - 3rem); 
}
.profileLabels > i {
  position: absolute;
  top: calc(50% - 4.5rem);
  left: calc(50% - 5rem);
  font-size: 8rem;
  color: var(--MDDwhite);
}
.profileMenuContainer > section > .profileLabels > div:last-child {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.65rem;
}
.profileMenuContainer > section > .profileLabels > div:last-child > button {
  height: 1.5rem;
  width: 10rem;
  border-radius: 0.25rem;
  font-size: var(--MDDsmallSize);
}
.profileMenuContainer
  > section
  > .profileLabels
  > div:last-child
  > button:last-child {
  color: var(--MDDwhite);
}
.profileMenuContainer
  > section
  > .profileLabels
  > div:last-child
  > button:last-child:hover {
  color: var(--MDDprimary);
}
.profileInputs {
  width: 100%;
  max-width: 30rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  gap: 0.5rem;
}
.profileInputs > h5 {
  height: 2rem;
  margin-bottom: 0.5rem;
}
.profileInputs > input {
  width: 100%;
  font-size: var(--MDDmediumSize);
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border-radius: 0.25rem;
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
  height: 2rem;
  padding: 0 0.75rem;
}
.profileInputs > input:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.profileMenuContainer > section > .profileInputs > div:last-child {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-top: 2.5rem;
  justify-content: flex-end;
  gap: 0.5rem;
}
