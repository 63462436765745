.sideBarContainer,
.fullSideBarContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  padding: 1.5rem 0 1.5rem 1rem;
  background-color: var(--MDDwhite);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-start;
  box-shadow: 0.25rem 0 0.5rem var(--MDDdefault);
  z-index: 5;
}
.sideBarContainer {
  width: 3rem;
}
.fullSideBarContainer {
  width: 18rem;
}
.logsSideBarContainer {
  width: 7rem;
  padding: 1.5rem 0;
}
.sideBarContainer > button,
.fullSideBarContainer > button {
  position: absolute;
  bottom: 2.5rem;
  right: 0.5rem;
}
.fullSideBarContainer > p {
  text-align: left;
  color: var(--MDDbasic);
  padding-bottom: 0.5rem;
}
.streamStoreSideBarContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.25rem;
}
.streamStoreSideBarContainer > h5 {
  font-size: var(--MDDsmallSize);
  font-weight: var(--MDDsemiBold);
}

.filterContainer {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  margin: 0.25rem 0;
}
.filterContainer > button:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.modifyFilter {
  border: 0.1rem solid var(--MDDdefault);
  background-color: var(--MDDwhite);
  border-radius: 0.25rem;
  width: 13rem;
  min-height: 2rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
}
.filterContainer > * {
  font-size: var(--MDDsmallSize);
}
.logsSideBarContainer > .streamStoreSideBarContainer {
  gap: 1.5rem;
}
.logsSideBarContainer > .streamStoreSideBarContainer > article {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.1rem;
  justify-content: flex-start;
  align-items: flex-start;
}
.logsSideBarContainer > .streamStoreSideBarContainer > article > p {
  font-size: var(--MDDsmallSize);
  width: 100%;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.logsSideBarContainer > .streamStoreSideBarContainer > article > button {
  border-radius: 0;
  font-size: var(--MDDsmallSize);
}
.logsSideBarContainer
  > .streamStoreSideBarContainer
  > article
  > .secondaryButton:hover {
  border: 0.1rem solid var(--MDDbackground);
}
.logsSideBarContainer .mergeLogsToggle {
  border-radius: 0;
  border: none;
  gap: 0;
  padding: 0;
  position: relative;
}
.logsSideBarContainer .mergeLogsToggle .mergeLogsBackground {
  height: 2rem;
  width: 3.5rem;
  background-color: var(--MDDprimary);
  position: absolute;
  left: 0;
  z-index: -1;
  border-radius: 0;
  transition: left 0.5s;
}
.logsSideBarContainer .mergeLogsToggle > button {
  width: 3.5rem;
  color: var(--MDDwhite);
  border-radius: 0;
}
.logsSideBarContainer
  > .streamStoreSideBarContainer
  > article:nth-child(2)
  > div:last-child {
  width: 7rem;
  height: 2rem;
  background-color: var(--MDDdefault);
  position: absolute;
  left: 0;
  top: 18.85rem;
  z-index: -2;
  border-radius: 0;
}
@media only screen and (max-width: 760px) {
  .sideBarContainer,
  .fullSideBarContainer {
    width: 100vw;
    position: relative;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .sideBarContainer {
    height: 3rem;
  }
  .fullSideBarContainer {
    min-height: 8rem;
    height: fit-content;
  }
  .streamStoreSideBarContainer {
    padding-top: 0;
    margin-bottom: 2rem;
  }
  .sideBarContainer > button,
  .fullSideBarContainer > button {
    bottom: 0.5rem;
    right: 0.5rem;
  }
}
