.streamCardContainer,
.streamCardContainer,
.topCardContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.streamCardContainer {
  background-color: var(--MDDwhite);
  border: none;
  width: 12.5rem;
  height: 18rem;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 0.8rem;
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
  padding: 0;

  transition: box-shadow 0.5s, border 0.25s;
}
.streamCardContainer:hover {
  z-index: 2;
  box-shadow: var(--MDDdarkShadow) 0.5rem 0.5rem 0.5rem,
    var(--MDDdarkShadow) -0.5rem -0.5rem 0.5rem,
    var(--MDDdarkShadow) 0.5rem -0.5rem 0.5rem,
    var(--MDDdarkShadow) -0.5rem 0.5rem 0.5rem;
}

.streamCardContainer > p {
  color: var(--MDDbasic);
  text-align: center;
  height: 3rem;
  font-size: var(--MDDmediumSize);
  padding: 0.05rem;    
}

.topCardContainer {
  width: 100%;
  height: 50%;
  background-color: var(--MDDbasic);
  justify-content: center;
  gap: 0.5rem;
}
.topCardContainer > img {
  height: 9rem;
  width: 100%;
  object-fit: cover;
}

.topCardContainer > i {
  font-size: 4rem;
  color: var(--MDDwhite);
}

.streamCardContainer > div > i {
  padding-left: 2px;
}

.streamCardContainer > div:last-child {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

.streamCardContainer > .streamCardVersion {
  color: #535353;
  font-size: 0.9rem;
}

.streamCardContainer > div:last-child > div {
  min-width: 1rem;
  width: fit-content;
  height: 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.streamCardContainer > button,
.streamCardContainer > div:last-child > i,
.streamCardContainer > article:last-child {
  margin-bottom: 1rem;
  cursor: pointer;
}

.streamCardContainer > div:last-child > i {
  font-size: 2rem;
}
.streamCardContainer > div:last-child i.bi-pencil-square {
  color: var(--MDDbasic);
  font-size: 1.5rem;
}
.streamCardContainer > div:last-child > div > p,
.streamCardContainer > div:last-child > div > i {
  color: var(--MDDdefault);
}
