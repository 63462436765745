.subscriptionCardContainer,
.subscriptionCardContainer > div:first-child,
.subscriptionCardContainer > div:first-child > div {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}
.subscriptionCardContainer {
  width: 100%;
  height: 2rem;
  justify-content: flex-start;
  border-radius: 0.5rem;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: -0.25rem;
  box-shadow: var(--MDDshadow) 0 0 0;
  transition: box-shadow 0.5s, height 0.5s, flex-flow 0.5s, align-items 0.5s,
    padding 0.5s;
}

.subscriptionCardContainer:hover,
.openedCard {
  background-color: transparent;
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
}
.subscriptionCardContainer:hover {
  cursor: pointer;
}
.openedCard {
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  height: 25rem;
}
.subscriptionCardContainer:hover > div:first-child > button,
.openedCard > div:first-child > button {
  color: var(--MDDwhite);
  transition: color 0.5s;
}
.subscriptionCardContainer > div:first-child {
  width: 100%;
  max-height: 2rem;
  justify-content: space-between;
  padding: 0 0.25rem 0 0.1rem;
  background-color: transparent;
  transition: background-color 0.5s;
}
.subscriptionCardContainer > div:first-child > i {
  font-size: 1.5rem;
  height: 2rem;
  width: 2rem;
  padding: 0.125rem 0.25rem;
}
.subscriptionCardContainer > div:first-child > div:nth-child(2),
.openedCard > div:first-child > div:nth-child(2) {
  width: 100%;
  padding: 0.25rem 0.25rem;
  justify-content: space-between;
}
.subscriptionCardContainer:hover > div:first-child,
.openedCard > div:first-child {
  background-color: var(--MDDsubscriptions);
}
.openedCard > div:nth-child(2) {
  width: 100%;
  margin: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.5rem;
  cursor: default;
}
.openedCard > div:nth-child(2) > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.openedCard > div:nth-child(2) > .labelsContainer {
  gap: 1rem;
}
.openedCard > div:nth-child(2) > .labelsContainer > label:nth-child(2) {
  height: 8rem;
}
.openedCard > div:nth-child(2) > .inputsContainer {
  padding: 1rem 0.1rem 1rem 0.5rem;
}
.openedCard > div:nth-child(2) > .inputsContainer > div:last-child {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.openedCard > div:nth-child(2) > .inputsContainer input,
.openedCard > div:nth-child(2) > .inputsContainer > textarea {
  font-size: var(--MDDmediumSize);
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
  transition: outline 0.5s;
  height: 2rem;
  width: 100%;
  border-radius: 0.25rem;
}
.openedCard > div:nth-child(2) > .inputsContainer > textarea {
  height: 8rem;
}
.openedCard > div:nth-child(2) > .inputsContainer > div:last-child > input {
  cursor: copy;
}
#editSubscriptionName:focus,
#editSubscriptionDescription:focus,
#subscriptionToken:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.subscriptionCardContainer > div:first-child > div > h5 {
  font-size: var(--MDDmediumSize);
  font-weight: var(--MDDsemiBold);
  width: 100%;
  text-align: left;
  color: var(--MDDbasic);
  transition: color 0.5s;
}
.subscriptionCardContainer > div:first-child p {
  color: var(--MDDbasic);
  transition: color 0.5s;
}
.subscriptionCardContainer:hover > div:first-child > div > h5,
.subscriptionCardContainer:hover > div:first-child > div > p,
.openedCard > div:first-child > div > p,
.openedCard > div:first-child > div > h5 {
  color: var(--MDDwhite);
}
.subscriptionCardContainer > div:first-child > div > p {
  width: 100%;
}
.subscriptionCardContainer > div:first-child > div > p:nth-child(4),
.subscriptionCardContainer > div:first-child > div > p:nth-child(5) {
  max-width: 7rem;
}
.openedCard > div:last-of-type {
  width: 100%;
  margin: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}
.openedCard > div:last-of-type > button:first-child,
.openedCard > div:last-of-type > div > button.altButtonColor {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.openedCard > div:last-of-type > div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  cursor: default;
  gap: 0.5rem;
}
.openedCard > div:last-of-type > button:first-child {
  width: fit-content;
  max-width: calc(100% - 30rem);
}
