.exportGlobalContainer {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, max-content));
  row-gap: 1rem;
  column-gap: 0.5rem;
  justify-content: center;
  max-width: 80%;
  padding-bottom: 1rem;
}
.exportGlobalContainer > .topDashboardContainer > div:nth-child(2) {
  border-radius: 1rem;
  border: 0.1rem solid var(--MDDbasic);
  gap: 0;
  padding: 0;
  position: relative;
}
.exportGlobalContainer
  > .topDashboardContainer
  > div:nth-child(2).confirmationButtonsContainer {
  justify-content: flex-end;
  border-radius: 0;
  border: 0.1rem solid transparent;
}
.exportNavBackground {
  height: 2rem;
  width: 7rem;
  background-color: var(--MDDbasic);
  position: absolute;
  left: 0;
  z-index: -1;
  border-radius: 1rem;
  transition: left 0.5s;
}
.exportNavBackground.streams ~ button:nth-child(2),
.exportNavBackground.abonnements ~ button:nth-child(3),
.exportNavBackground.dataviz ~ button:nth-child(4) {
  color: var(--MDDwhite);
}
.topDashboardPlaceholder {
  width: 13rem;
}
.exportGlobalContainer > .confirmationContainer {
  width: 100%;
  grid-column: 1 / -1;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  overflow: visible;
  margin-top: 1rem;
}
.exportCard {
  height: 16rem;
  width: 12rem;
  border-radius: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.5s;
}
.exportCard:first-child {
  background-color: var(--MDDbasic);
  border: 0.1rem solid var(--MDDbasic);
}
.exportCard:nth-child(2) {
  background-color: var(--MDDsubscriptions);
  border: 0.1rem solid var(--MDDsubscriptions);
}
.exportCard:nth-child(3) {
  background-color: var(--MDDdataviz);
  border: 0.1rem solid var(--MDDdataviz);
}
.exportCard:hover {
  box-shadow: var(--MDDdarkShadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDdarkShadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDdarkShadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDdarkShadow) -0.2rem 0.2rem 0.25rem;
}
.exportCard > h5,
.exportCard > p {
  color: var(--MDDwhite);
}
.exportCard > h5 {
  font-size: 6rem;
}
.listHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-column: 1 / -1;
  height: 3rem;
  padding: 0.4rem 0.5rem;
  border-bottom: 0.05rem solid var(--MDDbasic);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}
.listHeader > p {
  color: var(--MDDbasic);
  text-align: left;
  width: 100%;
}
.listHeader > p:first-child,
.dependenciesCard > button.mddLongButton {
  width: 20rem;
}
.listHeader > p:nth-child(2),
.dependenciesCard > p:nth-child(2) {
  max-width: 10rem;
}
.listHeader > p:nth-child(3),
.dependenciesCard > p:nth-child(3) {
  max-width: calc(100% - 32rem);
}
.listHeader > p:nth-child(4),
.dependenciesCard > i {
  width: 8rem;
}
.dependenciesCard {
  width: 100%;
  height: 3rem;
  grid-column: 1 / -1;
  border-radius: 0.5rem;
  padding: 0.4rem 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.5s;
}
.dependenciesCard:hover {
  box-shadow: var(--MDDdarkShadow) 0.4rem 0.4rem 0.25rem,
    var(--MDDdarkShadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDdarkShadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDdarkShadow) -0.2rem 0.2rem 0.25rem;
}
.nonEditableDependency:hover {
  box-shadow: none;
  cursor: default;
}
.dependenciesCard > button,
.dependenciesCard > p,
.dependenciesCard > i {
  color: var(--MDDwhite);
}
.dependenciesCard > button:first-child {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dependenciesCard > button:hover {
  color: var(--MDDprimary);
}
.streamDependency {
  border: 0.1rem solid var(--MDDbasic);
  background-color: var(--MDDbasic);
}
.subscriptionDependency {
  border: 0.1rem solid var(--MDDsubscriptions);
  background-color: var(--MDDsubscriptions);
}
.datavizDependency {
  border: 0.1rem solid var(--MDDdataviz);
  background-color: var(--MDDdataviz);
}
.archiveDependency {
  border: 0.1rem solid var(--MDDbasic);
  background-color: var(--MDDbasic);
}
.dependenciesCard > p {
  line-height: 2rem;
  width: 100%;
  text-align: left;
  transition: color 0.5s;
}
.dependenciesCard > i {
  font-size: 1.5rem;
  text-align: center;
  transition: color 0.5s;
}
.archiveCard {
  color: #fff;
  grid-column: 1/-1;
  padding: 0.2rem .2rem;
  width: 100%;
  border-radius: .5rem;
}
.archiveCard:hover {
  box-shadow: var(--MDDdarkShadow) 0.2rem 0.2rem 0.25rem,
  var(--MDDdarkShadow) -0.2rem -0.2rem 0.25rem,
  var(--MDDdarkShadow) 0.2rem -0.2rem 0.25rem,
  var(--MDDdarkShadow) -0.2rem 0.2rem 0.25rem;
}
.archiveCard.archiveClosed > div:first-child {
  display: flex;
  width: 100%;
  background-color: var(--MDDbasic);
  border: .1rem solid var(--MDDbasic);
  border-radius: .5rem;
}
.archiveCard.archiveOpened > div:first-child {
  display: flex;
  width: 100%;
  background-color: #1384a1;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.archiveCard > div:first-child > div {
  width: 100%;
  height: 3rem;
  display: flex;
  padding: .4rem;
}
.archiveCard .archiveSyncBtn {
  font-size: 1.5rem;
  font-weight: 400;
  padding: .15rem 0.35rem;
  width: 2.5rem;
}
.archiveCard > div:first-child > div > p {
  color: #fff;
  padding-top: 0.3rem;
}
.archiveCard .archiveTitle {
  width: 95%;
  padding-left: .3rem;
}
.archiveCard .archiveTitle:hover {
  cursor: pointer;
}
.archiveCard .archiveElementsCount {
  width: 2.5rem;
}
.archiveCard .archiveSyncBtn:hover {
  color: var(--MDDprimary);
  cursor: pointer;
}
.archiveCard .archiveDownloadBtn {
  padding-top: .2rem;
  padding-right: .4rem;
  font-size: 1.2rem;
  color: #fff;
}
.archiveCard .archiveDownloadBtn:hover {
  color: var(--MDDprimary);
  cursor: pointer;
}
.archiveCard .archiveCloseBtn {
  padding-top: .2rem;
  padding-right: .4rem;
  font-size: 1.2rem;
  color: #d7d7d7;
}
.archiveCard .archiveCloseBtn:hover {
  color: var(--MDDprimary);
  cursor: pointer;
}
.archiveCard > .archiveContent.archiveOpened {
  opacity: 1;
  transition: opacity 0.5s;
  padding: .4rem;
}
.archiveCard > .archiveContent.archiveClosed {
  opacity: 0;
  transition: opacity 0.5s;
}
.archiveElement {
  padding-top: 0.3rem;
  display: flex;
}
.archiveElement > p {
  padding-top: 0.3rem;
}
.archiveElement .title {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.archiveElement .description {
  width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.archiveElement .team {
  width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.archiveElement > p.type {
  border-radius: .5rem;
  color: #fff;
  padding-left: .45rem;
  padding-top: .35rem;
  width: 2rem;
}
.archiveElement > p.type.Stream {
  background-color: var(--MDDbasic);
}
.archiveElement > p.type.Version {
  background-color: var(--MDDbasic);
}
.archiveElement > p.type.Subscription {
  background-color: var(--MDDsubscriptions);
}
.archiveElement > p.type.Sataviz {
  background-color: var(--MDDdataviz);
}
.nonEditableDependency > i {
  color: transparent;
}
.progressContainer,
.syncContainer {
  width: 100%;
  grid-column: 1 / -1;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.syncContainer > h5 {
  align-self: flex-start;
}
.syncContainer > input {
  width: 100%;
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border-radius: 0.5rem;
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
  height: 2rem;
  padding: 0 0.75rem;
}
.syncContainer > input:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.syncContainer > div {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
