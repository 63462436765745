.lifeCycleContainer,
.versionPickerTimeLine {
  overflow: visible;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.lifeCycleContainer {
  width: 100%;
  position: relative;
  flex-flow: row nowrap;
  height: 150px;
}
.lifeCycleTimeLine {
  position: absolute;
  top: 49%;
  width: 100%;
  height: 0.2rem;
  background-color: var(--MDDprimary);
}
.inactiveTimeLine {
  background-color: var(--MDDdefault);
}
.lifeCycleContainer > div:not(.lifeCycleTimeLine) {
  position: relative;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.lifeCycleContainer > div > p {
  font-size: var(--MDDsmallSize);
}
.lifeCycleItem {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  z-index: 10;
  border: 0.2rem solid var(--MDDprimary);
  background-color: var(--MDDbackground);
  transition: width 0.25s, height 0.25s, border-radius 0.25s, top 0.25s;
}
.lifeCycleItem:hover,
.lifeCycleItem:focus {
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  cursor: pointer;
}
.inactiveTimeLine > div > .lifeCycleItem,
.inactiveTimeLine > div > .lifeCycleItem:hover,
.inactiveTimeLine > div > .lifeCycleItem:focus {
  border: 0.2rem solid var(--MDDdefault);
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  cursor: default;
}
.activeCycle {
  background-color: var(--MDDprimary);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
}
.invalidTime > p {
  color: var(--MDDstatusAlert);
}
.activeProgress,
.activeProgress:hover,
.activeProgress:focus {
  text-align: center;
  padding: 0.4rem 0 0 0;
  background-color: var(--MDDprimary);
  border-color: var(--MDDprimary);
  color: var(--MDDwhite);
}
.subTimeLine > div > .lifeCycleItem:hover,
.subTimeLine > div > .lifeCycleItem:focus {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  cursor: default;
  top: 2.6rem;
}
.versionPickerContainer,
.versionPickerContainer > div {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.versionPickerContainer {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background-color: var(--MDDmodalBackground);
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: visible;
  z-index: 20;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.versionPickerContainer > div {
  width: 100%;
  max-width: 75rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  max-height: calc(100vh - 2rem);
  overflow-y: visible;
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
  position: absolute;
  top: 1rem;
}
.topVersionPickerContainer {
  width: 100%;
  min-height: 7.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  padding: 1rem;
  box-shadow: var(--MDDdefault) 0.25rem 0.25rem 0.5rem;
}
.nonEditingTopContainer {
  min-height: 3rem;
}
.topVersionPickerContainer > p {
  width: 100%;
  color: var(--MDDprimary);
}
.topVersionPickerContainer > form {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.25rem;
}
.topVersionPickerContainer > form > input {
  font-size: var(--MDDmediumSize);
  background-color: var(--MDDwhite);
  color: var(--MDDbasic);
  border-radius: 0.25rem;
  border: 0.05rem solid var(--MDDdefault);
  outline: 0.05rem solid transparent;
  height: 2rem;
  padding: 0 0.75rem;
}
.topVersionPickerContainer > form > input:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.topVersionPickerContainer > form > input:first-child {
  width: 100%;
  max-width: 6rem;
}
.topVersionPickerContainer > form > input:nth-child(2) {
  width: 100%;
  max-width: 30rem;
}
.topVersionPickerContainer > form > button {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  margin: 0;
  border: 0.15rem solid var(--MDDprimary);
  background-color: var(--MDDprimary);
  font-size: var(--MDDsmallSize);
  color: var(--MDDwhite);
  transition: color 0.25s, border 0.25s, background-color 0.25s;
}
.topVersionPickerContainer > form > button:hover,
.topVersionPickerContainer > form > button:focus {
  border: 0.15rem solid var(--MDDprimary);
  background-color: var(--MDDwhite);
  color: var(--MDDprimary);
}
.versionPickerContainer > div > div {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 7.5rem - 2rem - 3rem);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}
.versionPickerTimeLine {
  width: 0.2rem;
  height: fit-content;
  margin: 1rem;
  padding: 1.5rem 0;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 1.5rem;
  background-color: var(--MDDprimary);
}
.versionPickerTimeLine > div {
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  overflow-x: visible;
  width: calc(100vw - 9rem);
  max-width: 62.5rem;
}
.versionPickerTimeLine > div > button:first-child,
.versionPickerTimeLine > div > .inactiveButton:first-child {
  color: transparent;
}
.versionPickerTimeLine > div > div:first-child,
.versionPickerTimeLine > div > div:nth-child(2) {
  height: 2rem;
  width: 2rem;
}
.versionPickerTimeLine > div:hover > button:first-child {
  color: unset;
}
.versionPickerTimeLine > div:hover > .inactiveButton:first-child {
  color: var(--MDDdefault);
}
.versionPickerTimeLine > div > .inactiveButton,
.versionPickerTimeLine > div > .inactiveButton:hover {
  border: 0.1rem solid transparent;
}
.versionPickerTimeLine > div i {
  height: 2rem;
  width: 2rem;
  font-size: 1rem;
  padding: 0.5rem;
  overflow: hidden;
}

.versionPickerTimeLine > div > div:last-of-type {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.versionPickerTimeLine > div > div > p:first-of-type {
  text-align: left;
  width: 2.5rem;
  font-size: var(--MDDsmallSize);
}
.versionPickerTimeLine > div > textarea {
  width: calc(((100vw - 9rem) / 2) - 3.5rem);
  max-width: calc(((62.5rem) / 2) - 3.5rem);
  font-size: var(--MDDsmallSize);
  background-color: transparent;
  color: var(--MDDbasic);
  border-radius: 0.25rem;
  border: none;
  outline: 0.05rem solid transparent;
  min-height: 2rem;
  padding: 0 0.25rem;
  transition: background-color 0.25s, border 0.25s, outline 0.25s;
  resize: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.versionPickerTimeLine > div > p.versionDescriptionForm {
  width: calc(((100vw - 9rem) / 2) - 3.5rem);
  max-width: calc(((62.5rem) / 2) - 3.5rem);
  color: var(--MDDbasic);
  min-height: 2rem;
  padding: 0 0.75rem;
}
.versionPickerTimeLine > div:hover > textarea,
.versionPickerTimeLine > .activeVersion > textarea {
  background-color: var(--MDDwhite);
  border: 0.05rem solid var(--MDDdefault);
}
.versionPickerTimeLine > div > textarea:focus {
  outline: 0.05rem solid var(--MDDprimary);
}
.versionPickerTimeLine > div > textarea::-webkit-scrollbar {
  display: none;
}
.versionPickerTimeLine > div > .versionTimeDetails {
  text-align: right;
  width: calc(((100vw - 9rem) / 2) - 18rem);
  max-width: calc(((62.5rem) / 2) - 18rem);
  font-size: var(--MDDsmallSize);
}
.versionPickerItem {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  margin: 0;
  border: 0.2rem solid var(--MDDprimary);
  background-color: var(--MDDbackground);
  font-size: var(--MDDsmallSize);
  transition: color 0.25s, border 0.25s;
}
.versionPickerItem:hover,
.versionPickerItem:focus {
  color: var(--MDDprimary);
  border: 0.2rem solid var(--MDDprimary);
}
.activeVersion {
  background-color: var(--MDDprimaryTransparent);
}
.activeVersion > p.versionPickerItem,
.activeVersion > p.versionPickerItem:hover,
.activeVersion > p.versionPickerItem:focus {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  margin: 0;
  text-align: center;
  cursor: default;
  padding: 0.75rem 0;
  background-color: var(--MDDbasic);
  border: 0.2rem solid var(--MDDprimary);
  color: var(--MDDwhite);
}
.versionPickerContainer > div > article:last-child {
  width: 100%;
  padding: 0.5rem;
  min-height: 3rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}
@media only screen and (max-width: 760px) {
  .versionPickerContainer {
    padding: 0;
  }
  .versionPickerTimeLine > div {
    width: auto;
  }
}
