.qosModalContainer {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background-color: var(--MDDmodalBackground);
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: visible;
  z-index: 20;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.qosModalContainer > section {
  width: 100%;
  max-width: 75rem;
  height: fit-content;
  min-height: 35rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  max-height: calc(100vh - 2rem);
  overflow-y: visible;
  background-color: var(--MDDwhite);
  border-radius: 0.5rem;
  position: absolute;
  top: 1rem;
}
.qosModalContainer > section > .qosHeader {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}
.qosModalContainer h4 {
  font-size: var(--MDDlargeSize);
  color: var(--MDDbasic);
}
.qosNavigationContainer {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.qosNavigationContainer > button:first-of-type {
  margin-left: -6rem;
}
.qosNavigationContainer > button:last-of-type {
  margin-right: -6rem;
}
.navigationToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  border-radius: 1rem;
  border: 0.1rem solid var(--MDDbasic);
  gap: 0;
  padding: 0;
  position: relative;
  z-index: 0;
}
.qosNavBackground {
  height: 2rem;
  width: 7rem;
  background-color: var(--MDDbasic);
  position: absolute;
  left: 0;
  z-index: -1;
  border-radius: 1rem;
  transition: left 0.5s;
}
.navigationToggle > button {
  color: var(--MDDbasic);
}
.qosNavBackground.quotidienne ~ button:nth-child(2),
.qosNavBackground.mensuelle ~ button:nth-child(3) {
  color: var(--MDDwhite);
}
.qosModalContainer > section > .chartContainer {
  width: 100%;
  height: fit-content;
  max-height: 40rem;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.chartContainer > div {
  width: 100%;
  height: 20rem;
  display: block;
}
.statusReason {
    width: 100%;
    text-align: left;
}
.qosModalContainer > section > article:last-child {
  width: 100%;
  padding: 1rem;
  min-height: 3rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}
