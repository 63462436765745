/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap"); */
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
/* @import "bootstrap/dist/css/bootstrap.min.css"; */
@font-face {
  font-family: "Source Code Pro";
  src: url("./utils//fonts/sourcecode/SourceCodePro-Light.ttf")
    format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("./utils//fonts/opensans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "JetBrains Mono";
  src: url("./utils//fonts/jetbrainsmono/JetBrainsMono-VariableFont_wght.ttf")
    format("woff2 supports variations");
  font-weight: 100 1000;
}

@font-face {
  font-family: "Alfa Slab One";
  src: url("./utils//fonts/alfaslabone/AlfaSlabOne-Regular.ttf")
    format("truetype");
  font-weight: normal;
}

:root {
  --MDDprimary: #2bb0fc;
  --MDDprimaryTransparent: #2bb0fc1e;
  --MDDbackground: #f4f4f4;
  --MDDbackgroundTransparent: #f4f4f4a4;
  --MDDdefault: #d4d4d4;
  --MDDshadow: #f0f0f0;
  --MDDdarkShadow: #a6a6a6;
  --MDDstatusOk: #c0e657;
  --MDDstatusOff: #9a9a9a;
  --MDDstatusWarning: #ff8700;
  --MDDstatusAlert: #fd7668;
  --MDDstatusFatal: #e11e02;
  --MDDwhite: #ffffff;
  --MDDbasic: #203b53;
  --MDDsubscriptions: #9f12b3;
  --MDDdataviz: #ff1f9a;
  --MDDexports: #ff5240;
  --MDDusers: #389fb7;
  --MDDmodalBackground: #000000a4;
  --MDDfont: "Open Sans";
  --MDDmediumSize: 16px;
  --MDDlargeSize: 20px;
  --MDDsmallSize: 13px;
  --MDDsemiBold: 600;
  --MDDregularFont: 400;
  font-size: var(--MDDmediumSize);
}
@media only screen and (min-width: 801px) {
  :root {
    --MDDmediumSize: 18px;
    --MDDlargeSize: 23px;
    --MDDsmallSize: 14px;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  line-height: initial;
  text-overflow: ellipsis;
}
body {
  font-size: var(--MDDmediumSize);
  font-weight: var(--MDDregularFont);
  background-color: var(--MDDbasic);
}
