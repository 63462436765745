h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
li,
label,
input {
  font-family: var(--MDDfont);
  color: var(--MDDbasic);
}

a {
  text-decoration: none !important;
  transition: color 0.5s;
}
a:hover {
  color: var(--MDDbackground);
}
.App {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  background-color: var(--MDDwhite);
}
/* .mainBody {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--MDDwhite);
  flex-grow: 1;
} */
p {
  margin: 0;
}

input {
  overflow-y: hidden;
}

nav {
  max-width: 34rem;
  height: 2rem;
  padding-top: 0.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

nav > ol {
  margin-bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
nav > ol > li {
  max-width: 16rem;
}

nav > ol > .active {
  color: var(--MDDdefault);
}
nav > ol > li > a {
  color: var(--MDDbasic);
}
nav > ol > li > a:hover {
  color: var(--MDDprimary);
}
.dashboardWithLargeSideBar {
  margin-left: 18rem;
}
.dashboardWithSideBar {
  margin-left: 3rem;
}
.dashboardWithoutSideBar {
  margin-left: 0rem;
}
.incorrectInput,
.incorrectInput:focus,
.incorrectInput:hover {
  outline: 0.05rem solid var(--MDDstatusAlert) !important;
}
.disabledInput,
.disabledInput:focus,
.disabledInput:hover {
  border: 0.05rem solid transparent !important;
}
.statusAlert {
  color: var(--MDDstatusAlert);
}
.statusWarning {
  color: var(--MDDstatusWarning);
}
.statusOk {
  color: var(--MDDstatusOk);
}
.statusOff {
  color: var(--MDDdefault);
}
.statusFatal {
  color: var(--MDDstatusFatal);
}
.subscriptionInfo {
  color: var(--MDDsubscriptions);
}
.noElementDisclaimer {
  width: 100%;
  grid-column: 1 / -1;
  height: fit-content;
  padding: 1.5rem 0 2.5rem 0;
  border-radius: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  box-shadow: var(--MDDshadow) 0.2rem 0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) 0.2rem -0.2rem 0.25rem,
    var(--MDDshadow) -0.2rem 0.2rem 0.25rem;
}
.noElementDisclaimer > h4 {
  width: 100%;
  text-align: center;
  color: var(--MDDbasic);
  padding: 0.5rem 1rem;
}
.noElementDisclaimer > p {
  width: 100%;
  text-align: center;
  padding: 0 1rem;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}
.noElementDisclaimer > button {
  width: 9rem;
  margin: 1rem 1rem 0 1rem;
}
@media only screen and (max-width: 760px) {
  .dashboardWithSideBar,
  .dashboardWithoutSideBar {
    margin-left: 0;
  }
  nav > ol > li:first-child,
  nav > ol > li:nth-child(2) {
    width: fit-content;
  }
  nav > ol > li {
    max-width: 14.5rem;
  }
}
